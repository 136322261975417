.mainVisualSlider {
  margin: 0 4.4vw;
  height: 100%;
  z-index: 0;
  @include mq(md) {
    margin: 0 4%;
    height: 118vw;
  }
  img {
    max-height: 600px;
    @include mq(md) {
      max-height: none;
      height: 100%;
    }
  }
}

.catchCopy {
  max-width: 1080px;
  margin: auto;
  width: 80%;
  position: absolute;
  left: 0;
  right: 0;
  top: 20%;
  @include mq(md) {
    top: 17%;
  }
  p {
    @include fz_vw_pc(49);
    font-weight: 500;
    color: #fff;
    text-shadow: #000 0 0 8px;
    position: relative;
    @include mq(md) {
      @include fz_vw_md(50);
    }
  }
  span {
    font-family: $BebasNeue;
    @include fz_vw_pc(18);
    line-height: 1.75;
    letter-spacing: 2.4px;
    color: #fff;
    text-shadow: #000 0 0 8px;
    @include mq(md) {
      @include fz_vw_md(20);
    }
  }
}

.infoBtn {
  position: absolute;
  bottom: -2.2vw;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 50;
  width: 44%;
  max-width: 600px;
  @include mq(fixed) {
    bottom: -30px;
  }
  @include mq(md) {
    bottom: -7vw;
    width: 88%;
  }
  .btn {
    background-color: #006CB6;
    padding: 1.2vw 3vw;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    width: 100%;
    transition: .5s;
    @include mq(fixed) {
      padding: 16px 40px;
    }
    @include mq(md) {
      padding: 4% 5%;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 5%;
      margin: auto;
      background-image: url(/images/icons/icon_arrow_long.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 2.2vw;
      height: 1vw;
      transition: .5s;
      @include mq(fixed) {
        width: 30px;
        height: 9px;
      }
      @include mq(md) {
        width: 4vw;
        height: 2vw;
      }
    }
    &.hover {
      background-color: #3f98d9;
      &::after {
        transform: translateX(5px);
      }
    }
  }
  p {
    @include fz_vw_pc(16);
    line-height: 1;
    letter-spacing: 1.6px;
    color: #ffffff;
    @include mq(md) {
      @include fz_vw_md(24);
      padding: 0;
    }
  }
  .infoHead {
    font-family: $BebasNeue;
    line-height: 2;
    font-weight: bold;
    padding-right: 10%;
    margin-right: 10%;
    border-right: solid 1px #80B6DA;
    padding-top: .2em;
    @include mq(fixed) {
      padding-right: 50px;
      margin-right: 50px;
    }
    @include mq(md) {
      padding-right: 2%;
      margin-right: 3%;
    }
    &::after {
      display: none;
    }
  }
}

.infoModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,0.8);
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  &Box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55%;
    max-width: 750px;
    height: auto;
    padding: 5% 5.85%;
    background-color: #fff;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.16);
    z-index: 150;
    @include mq(fixed) {
      padding: 70px 80px;
    }
    @include mq(md) {
      width: 90%;
      padding: 15% 10% 10%;
    }
    .closeBtn {
      position: absolute;
      top: 2vw;
      right: 2vw;
      width: 40px;
      height: 30px;
      cursor: pointer;
      @include mq(md) {
        width: 8vw;
        height: 4vw;
        top: 8vw;
        right: 6vw;
      }
      span {
        display: inline-block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #000;
        &:nth-of-type(1) {
          top: 0;
          transform: translateY(14px) rotate(-45deg);
          @include mq(md) {
            transform: translateY(2vw) rotate(-45deg);
          }
        }
        &:nth-of-type(2) {
          bottom: 0;
          transform: translateY(-14px) rotate(45deg);
          @include mq(md) {
            transform: translateY(-2vw) rotate(45deg);
          }
        }
      }
    }
    h2 {
      font-family: $BebasNeue;
      text-align: center;
      @include fz_vw_pc(40);
      line-height: 1.7;
      letter-spacing: 4px;
      text-align: center;
      color: #006cb6;
      @include mq(md) {
        @include fz_vw_md(60);
      }
      &::before,
      &::after {
        content: "";
        background-size: contain;
        background-repeat: no-repeat;
        vertical-align: middle;
        display: inline-block;
        width: 10%;
        height: 20px;
        @include mq(md) {
          width: 8vw;
          height: 1.3vw;
        }
      }
      &::before {
        background-image: url(/images/index/line_left.svg);
        margin-right: 5%;
      }
      &::after {
        background-image: url(/images/index/line_right.svg);
        margin-left: 5%;
      }
    }
    p {
      @include fz_vw_pc(16);
      line-height: 2.75;
      letter-spacing: 1.6px;
      @include mq(md) {
        @include fz_vw_md(23);
        line-height: 2.3;
      }
    }
  }
}
