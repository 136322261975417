@charset "UTF-8";
/* ================================================================================
値の定義
================================================================================ */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
/* ================================================================================
importファイル
================================================================================ */
/*! Type: YakuHanJP_Noto - Based on Noto Sans CJK JP */
@font-face {
  font-family: "BebasNeue";
  src: url("./fonts/BebasNeue/BebasNeue-Light.ttf") format("ttf"), url("./fonts/BebasNeue/BebasNeue-Light.woff") format("woff");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "BebasNeue";
  src: url("./fonts/BebasNeue/BebasNeue-Regular.ttf") format("ttf"), url("./fonts/BebasNeue/BebasNeue-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "BebasNeue";
  src: url("./fonts/BebasNeue/BebasNeue-Bold.ttf") format("ttf"), url("./fonts/BebasNeue/BebasNeue-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

/* ================================================================================
フォントサイズ VW設定
================================================================================ */
/*PC設定*/
/*タブレット設定*/
/*SP設定*/
/* ======================================================================

リセット

====================================================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #555;
  font-size: 62.5%;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 750px) {
  body {
    font-size: 100%;
  }
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #999;
  padding: .35em .625em .75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  color: #000;
  outline: none;
}

a:visited {
  color: #000;
  outline: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #999;
}

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}

label {
  font-weight: normal;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  padding: 0;
}

input::-ms-clear, input::-ms-reveal {
  visibility: hidden;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
}

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0;
}

@media screen and (max-width: 750px) {
  #responsibleCheck {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
@media screen and (max-width: 750px) {
  .pcOnly {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
}

@media screen and (max-width: 750px) {
  .spOnly {
    display: block !important;
  }
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #000;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.fixedBody {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

main {
  overflow-x: hidden;
}

/* ハイトカラー消去
------------------------------------------------------------- */
.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
}

main article section {
  display: block;
  width: 100%;
  position: relative;
}

main article section p {
  font-family: YakuHanJP_Noto, "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
}

a {
  color: #000;
}

img.ofi {
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

/* 共通デザイン
------------------------------------------------------------- */
.pageTop {
  padding: 7.32% 0;
  display: flex;
}

@media screen and (min-width: 1366px) {
  .pageTop {
    padding: 100px 0;
  }
}

@media screen and (max-width: 750px) {
  .pageTop {
    padding: 14% 0;
  }
}

.pageTopBtn {
  margin: auto;
  cursor: pointer;
  height: 5.85vw;
  width: 5%;
}

@media screen and (min-width: 1366px) {
  .pageTopBtn {
    height: 80px;
  }
}

@media screen and (max-width: 750px) {
  .pageTopBtn {
    height: 10vw;
  }
}

.pageTopBtn img {
  transition: .5s;
  height: 100%;
  margin: auto;
}

.pageTopBtn.hover img {
  transform: translateY(-5px);
}

.breadcrumb {
  max-width: 1080px;
  margin: 0 auto 20px;
  width: 80%;
}

@media screen and (max-width: 750px) {
  .breadcrumb {
    width: 90%;
  }
}

.breadcrumb ol {
  position: relative;
  width: 100%;
  padding-left: 3.2rem;
  word-break: break-all;
}

.breadcrumb ol::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 14px;
  height: 17px;
  margin: auto;
  content: "";
  background-image: url(/images/icons/icon_home.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.breadcrumb ol > li {
  display: inline;
  font-size: 1.4rem;
  font-size: 1.02489vw;
  letter-spacing: 0.7px;
}

@media screen and (min-width: 1366px) {
  .breadcrumb ol > li {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  .breadcrumb ol > li {
    font-size: 2.4rem;
    font-size: 3.2vw;
  }
}

.breadcrumb ol > li + li::before {
  padding: 0 7px 0 5px;
  content: ">";
}

/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* ================================================================
ヘッダー
================================================================ */
.headerLayout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 100;
  background-color: #fff;
  padding: 0 4.4vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: .5s;
}

@media screen and (max-width: 750px) {
  .headerLayout {
    padding: 0 5.3% 0 4%;
    height: 16vw;
  }
}

.headerLogo {
  width: 22.748815165876778%;
  max-width: 288px;
}

@media screen and (max-width: 750px) {
  .headerLogo {
    width: 100%;
    height: 60%;
    max-width: 370px;
  }
}

.headerLogo h1 {
  height: 100%;
}

.headerLogo h1 a {
  display: inline-block;
  height: 100%;
}

.headerLogo h1 a img {
  height: 100%;
}

.headerNav {
  background-color: #fff;
  z-index: -1;
  transition: .7s ease-in-out;
}

@media screen and (max-width: 750px) {
  .headerNav {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 16vw;
    height: auto;
    transform: translateY(-100%);
    opacity: 0;
  }
}

.headerNav ul {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 750px) {
  .headerNav ul {
    display: block;
    padding: 0 5.3%;
  }
}

.headerNav ul li {
  font-family: BebasNeue;
  font-size: 2rem;
  font-size: 1.46413vw;
  line-height: 1.75;
  letter-spacing: 2px;
  padding-right: 25px;
}

@media screen and (min-width: 1366px) {
  .headerNav ul li {
    font-size: 2rem;
  }
}

.headerNav ul li:last-child {
  padding: 0;
}

@media screen and (max-width: 750px) {
  .headerNav ul li:last-child {
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 750px) {
  .headerNav ul li {
    font-size: 5rem;
    font-size: 6.66667vw;
    padding-bottom: 20px;
    opacity: 0;
  }
}

.headerNav ul li a {
  display: block;
  cursor: pointer;
}

.headerNav ul li span {
  font-size: 2rem;
  font-size: 2.66667vw;
  font-weight: 300;
  line-height: 1.45;
  color: #000000;
}

.headerNav.show {
  transform: translateY(0);
  opacity: 1;
}

.headerNav.show li {
  opacity: 1;
  transition: 2.5s;
}

.headerNav.show li:nth-child(1) {
  transition-delay: 0.5s;
}

.headerNav.show li:nth-child(2) {
  transition-delay: 0.7s;
}

.headerNav.show li:nth-child(3) {
  transition-delay: 0.9s;
}

.headerNav.show li:nth-child(4) {
  transition-delay: 1.1s;
}

.headerNav.show li:nth-child(5) {
  transition-delay: 1.3s;
}

.headerNav.show li:nth-child(6) {
  transition-delay: 1.5s;
}

.headerNavBtn {
  position: relative;
  width: 32px;
  height: 24px;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .headerNavBtn {
    width: 11.1%;
    height: 40%;
  }
}

.headerNavBtn span {
  display: inline-block;
  transition: .4s;
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
  transition: .3s ease-in-out;
}

.headerNavBtn span:nth-of-type(1) {
  top: 0;
}

.headerNavBtn span:nth-of-type(2) {
  top: 11px;
}

@media screen and (max-width: 750px) {
  .headerNavBtn span:nth-of-type(2) {
    top: 49%;
  }
}

.headerNavBtn span:nth-of-type(3) {
  bottom: 0;
}

.headerNavBtn.open span:nth-of-type(1) {
  transform: translateY(11px) rotate(-135deg);
}

@media screen and (max-width: 750px) {
  .headerNavBtn.open span:nth-of-type(1) {
    transform: translateY(3vw) rotate(-135deg);
  }
}

.headerNavBtn.open span:nth-of-type(2) {
  opacity: 0;
}

.headerNavBtn.open span:nth-of-type(3) {
  transform: translateY(-11px) rotate(135deg);
}

@media screen and (max-width: 750px) {
  .headerNavBtn.open span:nth-of-type(3) {
    transform: translateY(-3vw) rotate(135deg);
  }
}

/* ================================================================================
フッター
================================================================================ */
footer#footer {
  max-width: 1080px;
  margin: auto;
  width: 80%;
}

@media screen and (max-width: 750px) {
  footer#footer {
    width: 90%;
  }
}

footer#footer .siteMap {
  border: solid #006cb6;
  border-width: 1px 0;
  padding-top: 30px;
}

@media screen and (max-width: 750px) {
  footer#footer .siteMap {
    padding-top: 20px;
  }
}

footer#footer .siteMap ul {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 750px) {
  footer#footer .siteMap ul {
    display: block;
  }
}

footer#footer .siteMap ul li {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  letter-spacing: 0.7px;
  position: relative;
  padding-left: 25px;
  text-indent: -25px;
  margin-bottom: 30px;
}

@media screen and (min-width: 1366px) {
  footer#footer .siteMap ul li {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  footer#footer .siteMap ul li {
    font-size: 2.4rem;
    font-size: 3.2vw;
    width: 100% !important;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 890px) and (min-width: 751px) {
  footer#footer .siteMap ul li {
    width: 50% !important;
  }
}

footer#footer .siteMap ul li:before {
  content: "";
  width: 15px;
  height: 2px;
  background-color: #006cb6;
  display: inline-block;
  vertical-align: super;
  margin-right: 10px;
}

footer#footer .siteMap ul li:nth-of-type(3n-2), footer#footer .siteMap ul li:nth-of-type(3n-1) {
  width: 30%;
}

footer#footer .siteMap ul li:nth-of-type(3n) {
  width: 40%;
}

footer#footer .copyRight {
  background-color: #fff;
  padding: 40px 0;
}

@media screen and (max-width: 750px) {
  footer#footer .copyRight {
    padding: 6.6% 0;
  }
}

footer#footer .copyRight p {
  font-size: 1.2rem;
  font-size: 0.87848vw;
  line-height: 2;
  letter-spacing: 1.2px;
  text-align: center;
}

@media screen and (min-width: 1366px) {
  footer#footer .copyRight p {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 750px) {
  footer#footer .copyRight p {
    font-size: 2rem;
    font-size: 2.66667vw;
  }
}

/* 見出し装飾
------------------------------------------------------------- */
/* アニメーション関連
------------------------------------------------------------- */
/* ボタン装飾
------------------------------------------------------------- */
.btnLayout {
  display: flex;
}

.btnLayout a {
  padding: 10px 50px;
  background-color: #006cb6;
  font-size: 1.4rem;
  font-size: 1.02489vw;
  line-height: 1.75;
  letter-spacing: 1.4px;
  text-align: center;
  color: #fff;
  display: block;
  position: relative;
  transition: .5s;
}

@media screen and (min-width: 1366px) {
  .btnLayout a {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  .btnLayout a {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    width: 100%;
  }
}

.btnLayout a::after {
  content: "";
  background-image: url(/images/icons/icon_arrow.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 1.46vw;
  height: .8vw;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  margin: auto;
  transition: .5s;
}

@media screen and (min-width: 1366px) {
  .btnLayout a::after {
    width: 20px;
    height: 10px;
  }
}

@media screen and (max-width: 750px) {
  .btnLayout a::after {
    width: 5vw;
    height: 2vw;
    right: 6vw;
  }
}

.btnLayout a.hover {
  background-color: #3f98d9;
}

.btnLayout a.hover::after {
  transform: translateX(5px);
}

@media screen and (max-width: 750px) {
  .btnLayout.small a {
    width: 88%;
    margin: auto;
  }
}

.columnLayout {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.columnLayout .col1 {
  flex-basis: 100%;
  max-width: 100%;
}

@media screen and (max-width: 750px) {
  .columnLayout .col1 {
    max-width: none;
  }
}

.columnLayout .col2 {
  flex-basis: 50%;
  max-width: 50%;
}

@media screen and (max-width: 750px) {
  .columnLayout .col2 {
    max-width: none;
  }
}

.columnLayout .col3 {
  flex-basis: 33%;
  max-width: 33%;
}

@media screen and (max-width: 750px) {
  .columnLayout .col3 {
    max-width: none;
  }
}

.columnLayout .col4 {
  flex-basis: 25%;
  max-width: 25%;
}

@media screen and (max-width: 750px) {
  .columnLayout .col4 {
    max-width: none;
  }
}

.columnLayout .col5 {
  flex-basis: 20%;
  max-width: 20%;
}

@media screen and (max-width: 750px) {
  .columnLayout .col5 {
    max-width: none;
  }
}

.columnLayout .col6 {
  flex-basis: 16%;
  max-width: 16%;
}

@media screen and (max-width: 750px) {
  .columnLayout .col6 {
    max-width: none;
  }
}

.columnLayout .col7 {
  flex-basis: 14%;
  max-width: 14%;
}

@media screen and (max-width: 750px) {
  .columnLayout .col7 {
    max-width: none;
  }
}

.columnLayout .col8 {
  flex-basis: 12%;
  max-width: 12%;
}

@media screen and (max-width: 750px) {
  .columnLayout .col8 {
    max-width: none;
  }
}

/* ================================================================================
トップページ
================================================================================ */
#indexTop {
  padding-top: 100px;
}

@media screen and (max-width: 750px) {
  #indexTop {
    padding-top: 16vw;
  }
}

#indexTop .container {
  max-width: 1080px;
  margin: auto;
  width: 80%;
}

@media screen and (max-width: 750px) {
  #indexTop .container {
    width: 90%;
  }
}

#indexTop .secTit {
  max-width: 1080px;
  margin: auto;
  width: 80%;
}

@media screen and (max-width: 750px) {
  #indexTop .secTit {
    width: 90%;
  }
}

#indexTop .secTit h2 {
  font-family: BebasNeue;
  font-size: 8rem;
  font-size: 5.85652vw;
  line-height: 1;
  letter-spacing: 4px;
  color: #006cb6;
  display: inline-block;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #indexTop .secTit h2 {
    font-size: 8rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .secTit h2 {
    font-size: 11rem;
    font-size: 14.66667vw;
  }
}

#indexTop .secTit h2::after {
  content: "";
  width: 100vw;
  height: 1px;
  background-color: #000;
  position: absolute;
  bottom: -3vw;
  right: -10px;
}

@media screen and (min-width: 1366px) {
  #indexTop .secTit h2::after {
    bottom: -40px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .secTit h2::after {
    bottom: -50%;
    right: 10%;
  }
}

#indexTop .secTit h2 span {
  font-weight: 300;
}

#indexTop .secTit p {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  line-height: 1;
  letter-spacing: 1.4px;
  color: #006cb6;
  padding-left: 2%;
}

@media screen and (min-width: 1366px) {
  #indexTop .secTit p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .secTit p {
    font-size: 2rem;
    font-size: 2.66667vw;
    padding-left: 2.5%;
  }
}

#indexTop .pageTop {
  border-top: solid 1px #eeeeee;
}

#indexTop .mainVisualSlider {
  margin: 0 4.4vw;
  height: 100%;
  z-index: 0;
}

@media screen and (max-width: 750px) {
  #indexTop .mainVisualSlider {
    margin: 0 4%;
    height: 118vw;
  }
}

#indexTop .mainVisualSlider img {
  max-height: 600px;
}

@media screen and (max-width: 750px) {
  #indexTop .mainVisualSlider img {
    max-height: none;
    height: 100%;
  }
}

#indexTop .catchCopy {
  max-width: 1080px;
  margin: auto;
  width: 80%;
  position: absolute;
  left: 0;
  right: 0;
  top: 20%;
}

@media screen and (max-width: 750px) {
  #indexTop .catchCopy {
    top: 17%;
  }
}

#indexTop .catchCopy p {
  font-size: 4.9rem;
  font-size: 3.58712vw;
  font-weight: 500;
  color: #fff;
  text-shadow: #000 0 0 8px;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #indexTop .catchCopy p {
    font-size: 4.9rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .catchCopy p {
    font-size: 5rem;
    font-size: 6.66667vw;
  }
}

#indexTop .catchCopy span {
  font-family: BebasNeue;
  font-size: 1.8rem;
  font-size: 1.31772vw;
  line-height: 1.75;
  letter-spacing: 2.4px;
  color: #fff;
  text-shadow: #000 0 0 8px;
}

@media screen and (min-width: 1366px) {
  #indexTop .catchCopy span {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .catchCopy span {
    font-size: 2rem;
    font-size: 2.66667vw;
  }
}

#indexTop .infoBtn {
  position: absolute;
  bottom: -2.2vw;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 50;
  width: 44%;
  max-width: 600px;
}

@media screen and (min-width: 1366px) {
  #indexTop .infoBtn {
    bottom: -30px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .infoBtn {
    bottom: -7vw;
    width: 88%;
  }
}

#indexTop .infoBtn .btn {
  background-color: #006CB6;
  padding: 1.2vw 3vw;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  width: 100%;
  transition: .5s;
}

@media screen and (min-width: 1366px) {
  #indexTop .infoBtn .btn {
    padding: 16px 40px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .infoBtn .btn {
    padding: 4% 5%;
  }
}

#indexTop .infoBtn .btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5%;
  margin: auto;
  background-image: url(/images/icons/icon_arrow_long.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 2.2vw;
  height: 1vw;
  transition: .5s;
}

@media screen and (min-width: 1366px) {
  #indexTop .infoBtn .btn::after {
    width: 30px;
    height: 9px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .infoBtn .btn::after {
    width: 4vw;
    height: 2vw;
  }
}

#indexTop .infoBtn .btn.hover {
  background-color: #3f98d9;
}

#indexTop .infoBtn .btn.hover::after {
  transform: translateX(5px);
}

#indexTop .infoBtn p {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  line-height: 1;
  letter-spacing: 1.6px;
  color: #ffffff;
}

@media screen and (min-width: 1366px) {
  #indexTop .infoBtn p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .infoBtn p {
    font-size: 2.4rem;
    font-size: 3.2vw;
    padding: 0;
  }
}

#indexTop .infoBtn .infoHead {
  font-family: BebasNeue;
  line-height: 2;
  font-weight: bold;
  padding-right: 10%;
  margin-right: 10%;
  border-right: solid 1px #80B6DA;
  padding-top: .2em;
}

@media screen and (min-width: 1366px) {
  #indexTop .infoBtn .infoHead {
    padding-right: 50px;
    margin-right: 50px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .infoBtn .infoHead {
    padding-right: 2%;
    margin-right: 3%;
  }
}

#indexTop .infoBtn .infoHead::after {
  display: none;
}

#indexTop .infoModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
}

#indexTop .infoModalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
  max-width: 750px;
  height: auto;
  padding: 5% 5.85%;
  background-color: #fff;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.16);
  z-index: 150;
}

@media screen and (min-width: 1366px) {
  #indexTop .infoModalBox {
    padding: 70px 80px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .infoModalBox {
    width: 90%;
    padding: 15% 10% 10%;
  }
}

#indexTop .infoModalBox .closeBtn {
  position: absolute;
  top: 2vw;
  right: 2vw;
  width: 40px;
  height: 30px;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  #indexTop .infoModalBox .closeBtn {
    width: 8vw;
    height: 4vw;
    top: 8vw;
    right: 6vw;
  }
}

#indexTop .infoModalBox .closeBtn span {
  display: inline-block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
}

#indexTop .infoModalBox .closeBtn span:nth-of-type(1) {
  top: 0;
  transform: translateY(14px) rotate(-45deg);
}

@media screen and (max-width: 750px) {
  #indexTop .infoModalBox .closeBtn span:nth-of-type(1) {
    transform: translateY(2vw) rotate(-45deg);
  }
}

#indexTop .infoModalBox .closeBtn span:nth-of-type(2) {
  bottom: 0;
  transform: translateY(-14px) rotate(45deg);
}

@media screen and (max-width: 750px) {
  #indexTop .infoModalBox .closeBtn span:nth-of-type(2) {
    transform: translateY(-2vw) rotate(45deg);
  }
}

#indexTop .infoModalBox h2 {
  font-family: BebasNeue;
  text-align: center;
  font-size: 4rem;
  font-size: 2.92826vw;
  line-height: 1.7;
  letter-spacing: 4px;
  text-align: center;
  color: #006cb6;
}

@media screen and (min-width: 1366px) {
  #indexTop .infoModalBox h2 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .infoModalBox h2 {
    font-size: 6rem;
    font-size: 8vw;
  }
}

#indexTop .infoModalBox h2::before, #indexTop .infoModalBox h2::after {
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  display: inline-block;
  width: 10%;
  height: 20px;
}

@media screen and (max-width: 750px) {
  #indexTop .infoModalBox h2::before, #indexTop .infoModalBox h2::after {
    width: 8vw;
    height: 1.3vw;
  }
}

#indexTop .infoModalBox h2::before {
  background-image: url(/images/index/line_left.svg);
  margin-right: 5%;
}

#indexTop .infoModalBox h2::after {
  background-image: url(/images/index/line_right.svg);
  margin-left: 5%;
}

#indexTop .infoModalBox p {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  line-height: 2.75;
  letter-spacing: 1.6px;
}

@media screen and (min-width: 1366px) {
  #indexTop .infoModalBox p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .infoModalBox p {
    font-size: 2.3rem;
    font-size: 3.06667vw;
    line-height: 2.3;
  }
}

#indexTop .about {
  margin-top: -80px;
  padding-top: 180px;
  position: relative;
}

@media screen and (max-width: 750px) {
  #indexTop .about {
    margin-top: 0;
  }
}

#indexTop .about::after {
  content: "";
  background-image: url(/images/index/line_right_long.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 10px;
  position: absolute;
  top: 280px;
  right: 3%;
}

@media screen and (max-width: 750px) {
  #indexTop .about::after {
    width: 14vw;
    top: 200px;
  }
}

#indexTop .about h3 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.2px;
  margin-bottom: 1.85%;
}

@media screen and (min-width: 1366px) {
  #indexTop .about h3 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .about h3 {
    font-size: 3.6rem;
    font-size: 4.8vw;
    margin-bottom: 8%;
  }
}

#indexTop .aboutMessage {
  position: relative;
  margin-top: -22%;
  padding: 28% 0 19%;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutMessage {
    margin-top: -300px;
    padding: 380px 0 260px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutMessage {
    margin-top: -90%;
    padding: 100% -0px 30%;
  }
}

#indexTop .aboutMessage::before, #indexTop .aboutMessage:after {
  content: "";
  position: absolute;
  z-index: -1;
}

#indexTop .aboutMessage::before {
  background: linear-gradient(175deg, #f8f8f8 0, #f8f8f8 83%, transparent 83%, transparent 100%);
  top: 0;
  left: 0;
  width: 94.15%;
  height: 100%;
}

@media screen and (max-width: 750px) {
  #indexTop .aboutMessage::before {
    width: 100%;
    background: linear-gradient(170deg, #f8f8f8 0, #f8f8f8 91%, transparent 91%, transparent 100%);
  }
}

#indexTop .aboutMessage::after {
  background-image: url(/images/index/bg_message.png);
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  bottom: 0;
  margin: auto;
  left: -10%;
  width: 52vw;
  height: 52vw;
  max-width: 716px;
  max-height: 716px;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5) {
  #indexTop .aboutMessage::after {
    background-image: url(/images/index/bg_message@2x.png);
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutMessage::after {
    width: 95vw;
    height: 95vw;
    top: 50vw;
    margin: 0;
  }
}

#indexTop .aboutMessage p {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  line-height: 3.3;
  letter-spacing: 0.7px;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutMessage p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutMessage p {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    line-height: 2;
    letter-spacing: 1.4px;
  }
}

#indexTop .aboutMessage .name {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  line-height: 2;
  letter-spacing: 0.8px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 3%;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutMessage .name {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutMessage .name {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    margin-top: 6.4%;
  }
}

#indexTop .aboutMessage .name span {
  width: 11%;
  max-width: 116px;
  margin-left: 15px;
}

@media screen and (max-width: 750px) {
  #indexTop .aboutMessage .name span {
    width: 21.6vw;
    margin-left: 6%;
  }
}

#indexTop .aboutMessage .name img {
  width: 100%;
  height: 100%;
}

#indexTop .aboutVision {
  position: relative;
  padding-bottom: 16%;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutVision {
    padding-bottom: 230px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutVision {
    padding-bottom: 0;
  }
}

#indexTop .aboutVision::before {
  content: "";
  background-image: url(/images/index/bg_vision.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5) {
  #indexTop .aboutVision::before {
    background-image: url(/images/index/bg_vision@2x.jpg);
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutVision::before {
    height: 20%;
  }
}

#indexTop .aboutVision .visionTxt {
  font-size: 3rem;
  font-size: 2.19619vw;
  line-height: 1.67;
  letter-spacing: 3px;
  text-align: center;
  color: #006cb6;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutVision .visionTxt {
    font-size: 3rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutVision .visionTxt {
    font-size: 4rem;
    font-size: 5.33333vw;
  }
}

#indexTop .aboutVision .visionTxt span {
  display: inline-block;
  padding-right: 50px;
}

@media screen and (max-width: 750px) {
  #indexTop .aboutVision .visionTxt span {
    padding: 0;
    line-height: 1.5;
  }
}

#indexTop .aboutVision .visionTxt span:last-child {
  padding-right: 0;
  padding-left: 50px;
}

@media screen and (max-width: 750px) {
  #indexTop .aboutVision .visionTxt span:last-child {
    padding: 0;
  }
}

#indexTop .aboutVision .visionTxt::before, #indexTop .aboutVision .visionTxt::after {
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  display: inline-block;
  width: 60px;
  height: 10px;
}

@media screen and (max-width: 750px) {
  #indexTop .aboutVision .visionTxt::before, #indexTop .aboutVision .visionTxt::after {
    display: none;
  }
}

#indexTop .aboutVision .visionTxt::before {
  background-image: url(/images/index/line_left.svg);
}

#indexTop .aboutVision .visionTxt::after {
  background-image: url(/images/index/line_right.svg);
}

#indexTop .aboutVision .en {
  font-family: BebasNeue;
  font-size: 1.6rem;
  font-size: 1.1713vw;
  font-weight: bold;
  line-height: 3.13;
  letter-spacing: 2.4px;
  text-align: center;
  color: #006cb6;
  margin-bottom: 7.4%;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutVision .en {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutVision .en {
    font-size: 2.4rem;
    font-size: 3.2vw;
    margin-bottom: 13.3%;
  }
}

#indexTop .aboutVision .visionColumn {
  justify-content: center;
}

@media screen and (max-width: 750px) {
  #indexTop .aboutVision .visionColumn {
    display: block;
  }
}

#indexTop .aboutVision .visionColumn .col3 {
  padding: 0 3%;
  border-right: solid 1px #000;
}

@media screen and (max-width: 750px) {
  #indexTop .aboutVision .visionColumn .col3 {
    border: none;
    padding: 0;
    margin-bottom: 13%;
  }
}

#indexTop .aboutVision .visionColumn .col3:last-child {
  border: none;
}

#indexTop .aboutVision .visionColumn p {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  line-height: 2.14;
  letter-spacing: 1.4px;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutVision .visionColumn p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutVision .visionColumn p {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    padding: 0 7%;
    line-height: 2;
  }
}

#indexTop .aboutVision .visionColumn .visionTit {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  font-weight: bold;
  line-height: 2.08;
  letter-spacing: 2.4px;
  text-align: center;
  margin: 4% 0 10%;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutVision .visionColumn .visionTit {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutVision .visionColumn .visionTit {
    font-size: 3.4rem;
    font-size: 4.53333vw;
    margin: 7.4% 0 3%;
  }
}

#indexTop .aboutVision .visionColumn .visionTit span {
  font-family: BebasNeue;
  font-size: 1.4rem;
  font-size: 1.02489vw;
  line-height: 1;
  letter-spacing: 1.4px;
  display: block;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutVision .visionColumn .visionTit span {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutVision .visionColumn .visionTit span {
    font-size: 2.4rem;
    font-size: 3.2vw;
  }
}

#indexTop .aboutPhilosophy {
  margin-bottom: 11%;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutPhilosophy {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutPhilosophy {
    margin: 0;
    position: relative;
  }
  #indexTop .aboutPhilosophy::after {
    content: "";
    position: absolute;
    z-index: -1;
    background-image: url(/images/index/bg_pattern_beige.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 94vw;
    height: 63vw;
    bottom: -2%;
    left: 0;
  }
}

@media screen and (max-width: 750px) and (-webkit-min-device-pixel-ratio: 1.5), screen and (max-width: 750px) and (min-resolution: 1.5) {
  #indexTop .aboutPhilosophy::after {
    background-image: url(/images/index/bg_pattern_beige@2x.png);
  }
}

#indexTop .aboutPhilosophy.open::after {
  display: none;
  transition: .5s;
}

#indexTop .aboutPhilosophyHead {
  position: relative;
  margin-top: -22.7%;
  padding: 22% 0 4.4%;
  overflow: hidden;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutPhilosophyHead {
    margin-top: -310px;
    padding: 300px 0 60px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutPhilosophyHead {
    margin-top: -40%;
    padding: 75% -0px 12%;
  }
}

#indexTop .aboutPhilosophyHead::before, #indexTop .aboutPhilosophyHead:after {
  content: "";
  position: absolute;
  z-index: -1;
}

#indexTop .aboutPhilosophyHead::before {
  background: linear-gradient(-5deg, #f7f6f0 0, #f7f6f0 70%, transparent 70%, transparent 100%);
  top: 0;
  right: 0;
  width: 94.15%;
  height: 100%;
}

@media screen and (max-width: 750px) {
  #indexTop .aboutPhilosophyHead::before {
    background: linear-gradient(-10deg, #f7f6f0 0, #f7f6f0 70%, transparent 70%, transparent 100%);
    width: 100%;
  }
}

#indexTop .aboutPhilosophyHead::after {
  background-image: url(/images/index/bg_pattern_beige.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 60vw;
  height: 40vw;
  max-width: 794px;
  max-height: 532px;
  bottom: -30%;
  left: 6%;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5) {
  #indexTop .aboutPhilosophyHead::after {
    background-image: url(/images/index/bg_pattern_beige@2x.png);
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutPhilosophyHead::after {
    width: 94vw;
    height: 63vw;
    bottom: 25%;
    left: 0;
  }
}

#indexTop .aboutPhilosophyHead h4 {
  font-size: 1.8rem;
  font-size: 1.31772vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutPhilosophyHead h4 {
    font-size: 1.8rem;
  }
}

#indexTop .aboutPhilosophy h4 {
  font-weight: bold;
  line-height: 2.25;
  letter-spacing: 0.8px;
  margin-bottom: 20px;
}

@media screen and (max-width: 750px) {
  #indexTop .aboutPhilosophy h4 {
    font-size: 3.2rem;
    font-size: 4.26667vw;
    margin-bottom: 3%;
  }
}

#indexTop .aboutPhilosophy p {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  line-height: 2.14;
  letter-spacing: 0.7px;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutPhilosophy p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutPhilosophy p {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    line-height: 1.71;
    letter-spacing: 1.4px;
  }
}

#indexTop .aboutPhilosophyBtn {
  margin: 50px auto 0;
  text-align: center;
  width: 11vw;
  max-width: 160px;
  position: relative;
}

@media screen and (max-width: 750px) {
  #indexTop .aboutPhilosophyBtn {
    width: 36vw;
    max-width: none;
  }
}

#indexTop .aboutPhilosophyBtn::before, #indexTop .aboutPhilosophyBtn::after {
  content: "";
  width: 100px;
  height: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #000;
  display: block;
}

@media screen and (max-width: 750px) {
  #indexTop .aboutPhilosophyBtn::before, #indexTop .aboutPhilosophyBtn::after {
    width: 60%;
  }
}

#indexTop .aboutPhilosophyBtn::before {
  left: -120px;
}

@media screen and (max-width: 750px) {
  #indexTop .aboutPhilosophyBtn::before {
    left: -75%;
  }
}

#indexTop .aboutPhilosophyBtn::after {
  right: -120px;
}

@media screen and (max-width: 750px) {
  #indexTop .aboutPhilosophyBtn::after {
    right: -75%;
  }
}

#indexTop .aboutPhilosophyBtn a {
  font-family: BebasNeue;
  font-size: 1.6rem;
  font-size: 1.1713vw;
  letter-spacing: .8px;
  color: #fff;
  background-color: #000;
  padding: 10px 0;
  cursor: pointer;
  outline: none;
  display: block;
  transition: .5s;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutPhilosophyBtn a {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutPhilosophyBtn a {
    font-size: 4rem;
    font-size: 5.33333vw;
    padding: 3% 14%;
  }
}

#indexTop .aboutPhilosophyBtn a.hover {
  background-color: #555;
}

#indexTop .aboutPhilosophyDetail {
  position: relative;
  padding: 1.5% 0 4.4%;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutPhilosophyDetail {
    padding: 20px 0 60px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutPhilosophyDetail {
    padding: 0 0 35%;
  }
}

#indexTop .aboutPhilosophyDetail::before, #indexTop .aboutPhilosophyDetail:after {
  content: "";
  position: absolute;
  z-index: -1;
}

#indexTop .aboutPhilosophyDetail::before {
  background: #f7f6f0;
  bottom: 0;
  right: 0;
  width: 94.15%;
  height: 100%;
}

@media screen and (max-width: 750px) {
  #indexTop .aboutPhilosophyDetail::before {
    width: 100%;
  }
}

#indexTop .aboutPhilosophyDetail::after {
  background-image: url(/images/index/bg_pattern_beige.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 47vw;
  height: 31vw;
  max-width: 637px;
  max-height: 426px;
  bottom: 7%;
  right: 2%;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5) {
  #indexTop .aboutPhilosophyDetail::after {
    background-image: url(/images/index/bg_pattern_beige@2x.png);
  }
}

@media screen and (max-width: 750px) {
  #indexTop .aboutPhilosophyDetail::after {
    width: 94vw;
    height: 63vw;
    bottom: 60%;
    left: 0;
  }
}

#indexTop .aboutPhilosophyDetail.open::after {
  display: none;
}

#indexTop .aboutPhilosophyDetail h4 {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  margin-bottom: 10px;
  margin-top: 20px;
}

@media screen and (min-width: 1366px) {
  #indexTop .aboutPhilosophyDetail h4 {
    font-size: 1.6rem;
  }
}

#indexTop .aboutPhilosophyDetail h4:first-child {
  margin-top: 0;
}

#indexTop .company {
  position: relative;
}

@media screen and (max-width: 750px) {
  #indexTop .company::before {
    content: "";
    background-image: url(/images/index/line_left_long.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 14vw;
    height: 10px;
    position: absolute;
    top: 64vw;
    right: 3%;
  }
}

#indexTop .company::after {
  content: "";
  background-image: url(/images/index/line_right_long.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 10px;
  position: absolute;
  bottom: 2%;
  left: 3%;
}

@media screen and (max-width: 750px) {
  #indexTop .company::after {
    width: 14vw;
    bottom: 20vw;
    left: 5.3%;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .companyVisual img {
    height: 56vw;
  }
}

#indexTop .company .secTit {
  transform: translateY(-35%);
}

#indexTop .companyProfile {
  margin-top: 30px;
}

#indexTop .companyProfile dl {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  font-size: 1.02489vw;
  line-height: 2.14;
  letter-spacing: 1.4px;
}

@media screen and (min-width: 1366px) {
  #indexTop .companyProfile dl {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .companyProfile dl {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    display: block;
    line-height: 1.71;
    letter-spacing: 0.4px;
  }
}

#indexTop .companyProfile dl dt {
  flex-basis: 12%;
  font-weight: bold;
  color: #006cb6;
}

#indexTop .companyProfile dl dd {
  flex-basis: 88%;
  padding-bottom: 3.16%;
}

@media screen and (max-width: 750px) {
  #indexTop .companyProfile dl dd {
    padding-bottom: 5.3%;
  }
}

#indexTop .companyProfile dl dd .btnLayout {
  padding-top: 1.6%;
}

@media screen and (max-width: 750px) {
  #indexTop .companyProfile dl dd .btnLayout {
    padding-top: 4%;
  }
}

#indexTop .companyProfile .companyName {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  font-weight: bold;
  line-height: 1.8;
}

@media screen and (min-width: 1366px) {
  #indexTop .companyProfile .companyName {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .companyProfile .companyName {
    font-size: 2.8rem;
    font-size: 3.73333vw;
  }
}

#indexTop .companyProfile .companyName span {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  display: block;
  font-weight: 500;
}

@media screen and (min-width: 1366px) {
  #indexTop .companyProfile .companyName span {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .companyProfile .companyName span {
    font-size: 2.8rem;
    font-size: 3.73333vw;
  }
}

#indexTop .companyDetail {
  position: relative;
  padding-top: 22%;
  margin-top: -11%;
}

@media screen and (min-width: 1366px) {
  #indexTop .companyDetail {
    padding-top: 300px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail {
    padding: 32% 0 34.8%;
  }
}

#indexTop .companyDetail::before {
  content: "";
  background: linear-gradient(-5deg, #edf3f9 0, #edf3f9 86%, transparent 86%, transparent 100%);
  position: absolute;
  top: 0;
  right: 0;
  width: 94.15%;
  height: 100%;
  z-index: -1;
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail::before {
    background: linear-gradient(-10deg, #edf3f9 0, #edf3f9 96%, transparent 96%, transparent 100%);
    width: 100%;
  }
}

#indexTop .companyDetail h3 {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: 0.9px;
  color: #006cb6;
  text-indent: -10px;
  flex-basis: 100%;
  margin: 10% 0 4%;
}

@media screen and (min-width: 1366px) {
  #indexTop .companyDetail h3 {
    font-size: 1.8rem;
  }
}

#indexTop .companyDetail h3:first-of-type {
  margin-top: 0;
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail h3 {
    font-size: 3rem;
    font-size: 4vw;
    margin: 7.5% 0 3%;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail h3.groupTit::before {
    content: "";
    background-image: url(/images/index/line_three.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 18.8vw;
    height: 3.2vw;
    display: block;
    margin: 10vw auto;
  }
}

#indexTop .companyDetail p {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  line-height: 2.14;
  letter-spacing: 0.7px;
}

@media screen and (min-width: 1366px) {
  #indexTop .companyDetail p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail p {
    font-size: 2.6rem;
    font-size: 3.46667vw;
  }
}

#indexTop .companyDetail .col2 {
  flex-basis: 46%;
  max-width: 46%;
  margin-bottom: 7.4%;
  padding-left: 10px;
  position: relative;
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail .col2 {
    flex-basis: 100%;
    max-width: none;
    margin-bottom: 0;
  }
  #indexTop .companyDetail .col2::after {
    content: "";
    background-image: url(/images/index/line_three.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 18.8vw;
    height: 3.2vw;
    display: block;
    margin: 10vw auto;
  }
  #indexTop .companyDetail .col2:last-of-type::after {
    display: none;
  }
}

#indexTop .companyDetail .line {
  width: 1px;
  background-color: #000;
  margin-bottom: 7.4%;
}

#indexTop .companyDetail .stockHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 9%;
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail .stockHolder {
    display: block;
    padding-bottom: 4%;
  }
}

#indexTop .companyDetail .stockHolder p {
  flex-basis: 80%;
  max-width: 80%;
  border-right: 1px solid;
  padding-right: 2%;
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail .stockHolder p {
    border: none;
    max-width: none;
  }
}

#indexTop .companyDetail .stockHolder p span {
  display: block;
  font-size: 1.6rem;
  font-size: 1.1713vw;
  font-weight: bold;
}

@media screen and (min-width: 1366px) {
  #indexTop .companyDetail .stockHolder p span {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail .stockHolder p span {
    font-size: 2.8rem;
    font-size: 3.73333vw;
  }
}

#indexTop .companyDetail .stockHolder .rate {
  flex-basis: 20%;
  max-width: 20%;
  line-height: 1.71;
  text-align: center;
  font-weight: bold;
  border: none;
  padding: 0;
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail .stockHolder .rate {
    font-weight: normal;
    text-align: left;
    border-top: 1px solid;
    padding-top: 2%;
    margin-top: 2%;
    max-width: none;
  }
}

#indexTop .companyDetail .stockHolder .rate span {
  font-size: 3rem;
  font-size: 2.19619vw;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: 3px;
}

@media screen and (min-width: 1366px) {
  #indexTop .companyDetail .stockHolder .rate span {
    font-size: 3rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail .stockHolder .rate span {
    font-size: 3rem;
    font-size: 4vw;
    display: inline-block;
    margin-left: 10px;
  }
}

#indexTop .companyDetail .small {
  font-size: 1.2rem;
  font-size: 0.87848vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .companyDetail .small {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail .small {
    font-size: 2.4rem;
    font-size: 3.2vw;
  }
}

#indexTop .companyDetail dl {
  display: flex;
  flex-wrap: wrap;
}

#indexTop .companyDetail dl dt {
  flex-basis: 30%;
  font-size: 1.6rem;
  font-size: 1.1713vw;
  font-weight: bold;
  line-height: 2.5625;
  letter-spacing: 0.7px;
}

@media screen and (min-width: 1366px) {
  #indexTop .companyDetail dl dt {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail dl dt {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    line-height: 1.71;
    letter-spacing: 1.4px;
  }
}

#indexTop .companyDetail dl dd {
  flex-basis: 70%;
  font-size: 1.4rem;
  font-size: 1.02489vw;
  line-height: 2.92857;
  letter-spacing: 1.4px;
}

@media screen and (min-width: 1366px) {
  #indexTop .companyDetail dl dd {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail dl dd {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    line-height: 1.71;
  }
}

#indexTop .companyDetail ul li {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  line-height: 2.14;
  letter-spacing: 0.7px;
  margin-bottom: 6%;
  padding-left: 25px;
  text-indent: -25px;
}

@media screen and (min-width: 1366px) {
  #indexTop .companyDetail ul li {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail ul li {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    margin-bottom: 3%;
  }
}

#indexTop .companyDetail ul li:before {
  content: "";
  width: 15px;
  height: 2px;
  background-color: #006cb6;
  display: inline-block;
  vertical-align: super;
  margin-right: 10px;
}

#indexTop .companyDetail .group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

#indexTop .companyDetail .group img {
  margin-right: 30px;
  margin-bottom: 6%;
  max-width: 100px;
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail .group img {
    margin: 4.5% auto 7.5%;
    width: 28%;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .companyDetail .group .btnLayout {
    width: 100%;
  }
}

#indexTop .history {
  position: relative;
  padding-top: 11%;
}

@media screen and (min-width: 1366px) {
  #indexTop .history {
    padding-top: 150px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .history {
    padding-top: 0;
  }
}

#indexTop .historyLayout {
  position: relative;
}

@media screen and (max-width: 750px) {
  #indexTop .historyLayout {
    display: flex;
    flex-direction: column;
  }
}

#indexTop .historyLayout::before, #indexTop .historyLayout:after {
  content: "";
  position: absolute;
  z-index: -1;
  background-image: url(/images/index/bg_pattern_blue.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 46vw;
  max-width: 628px;
  height: 30vw;
  max-height: 419px;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5) {
  #indexTop .historyLayout::before, #indexTop .historyLayout:after {
    background-image: url(/images/index/bg_pattern_blue@2x.png);
  }
}

@media screen and (max-width: 750px) {
  #indexTop .historyLayout::before, #indexTop .historyLayout:after {
    width: 93.2vw;
    height: 62.4vw;
  }
}

#indexTop .historyLayout::before {
  top: 0;
  left: 5%;
}

@media screen and (max-width: 750px) {
  #indexTop .historyLayout::before {
    top: 50%;
    left: 0;
  }
}

#indexTop .historyLayout::after {
  top: -11vw;
  right: 2%;
}

@media screen and (min-width: 1366px) {
  #indexTop .historyLayout::after {
    top: -150px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .historyLayout::after {
    top: -34.8vw;
    right: 0;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .history .secTit {
    transform: translateY(-35%);
    z-index: 10;
  }
}

#indexTop .historyTxt {
  width: 60%;
  margin-top: 7.4%;
  font-size: 1.4rem;
  font-size: 1.02489vw;
  line-height: 3.29;
  letter-spacing: 0.7px;
}

@media screen and (min-width: 1366px) {
  #indexTop .historyTxt {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .historyTxt {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    line-height: 2;
    letter-spacing: 1.4px;
    width: 100%;
    margin-top: 5%;
  }
}

#indexTop .historyTxt p {
  margin-bottom: 7.7%;
}

@media screen and (max-width: 750px) {
  #indexTop .historyTxt p {
    margin-bottom: 6%;
  }
}

#indexTop .historyImg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 37.5%;
}

@media screen and (max-width: 750px) {
  #indexTop .historyImg {
    position: static;
    order: -1;
    width: 100%;
  }
}

#indexTop .historyImg img {
  height: 100%;
}

@media screen and (max-width: 750px) {
  #indexTop .historyImg img {
    height: 56vw;
  }
}

#indexTop .historyImg::after {
  content: "";
  background-image: url(/images/index/line_left_long.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 10px;
  position: absolute;
  bottom: -50px;
  right: 0;
}

@media screen and (max-width: 750px) {
  #indexTop .historyImg::after {
    width: 14vw;
    bottom: -13vw;
    right: 5.3%;
  }
}

#indexTop .business {
  position: relative;
  padding: 6.6% 0 5.85%;
  margin-bottom: 11%;
}

@media screen and (min-width: 1366px) {
  #indexTop .business {
    padding: 90px 0 80px;
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .business {
    padding: 27% 0 40%;
    margin: 0 0 -5%;
  }
}

#indexTop .business::before {
  content: "";
  background: linear-gradient(5deg, #f7f6f0 0, #f7f6f0 80%, transparent 80%, transparent 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 94.15%;
  height: 100%;
  z-index: -1;
}

@media screen and (max-width: 750px) {
  #indexTop .business::before {
    background: linear-gradient(10deg, #f7f6f0 0, #f7f6f0 90%, transparent 90%, transparent 100%);
    width: 100%;
  }
}

#indexTop .business h3 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.2px;
  margin: 7.4% 0 1.85%;
}

@media screen and (min-width: 1366px) {
  #indexTop .business h3 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .business h3 {
    font-size: 3.6rem;
    font-size: 4.8vw;
    line-height: 1.67;
    letter-spacing: 1.8px;
    margin: 15% 0 5%;
  }
}

#indexTop .business p {
  font-size: 1.4rem;
  font-size: 1.02489vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .business p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .business p {
    font-size: 2rem;
    font-size: 2.66667vw;
  }
}

#indexTop .business .container p {
  line-height: 3.29;
  letter-spacing: 0.7px;
}

@media screen and (max-width: 750px) {
  #indexTop .business .container p {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    line-height: 2;
    letter-spacing: 1.4px;
  }
}

#indexTop .business h4 {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  font-weight: 900;
  line-height: 2.14;
  letter-spacing: 0.7px;
  text-align: center;
  color: #006cb6;
  margin: 4.6% 0;
}

@media screen and (min-width: 1366px) {
  #indexTop .business h4 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .business h4 {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    line-height: 1.71;
    letter-spacing: 1.4px;
    font-weight: normal;
    margin: 10% 0;
  }
}

#indexTop .business h4 span {
  display: block;
  font-size: 2.4rem;
  font-size: 1.75695vw;
  line-height: 1.25;
  letter-spacing: 1.2px;
}

@media screen and (min-width: 1366px) {
  #indexTop .business h4 span {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .business h4 span {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    line-height: 1.71;
    letter-spacing: 1.4px;
    font-weight: bold;
  }
}

#indexTop .business h4 span::before, #indexTop .business h4 span::after {
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  display: inline-block;
  width: 60px;
  height: 10px;
  background-image: url(/images/index/line_left.svg);
  margin-right: 10px;
}

@media screen and (max-width: 750px) {
  #indexTop .business h4 span::before, #indexTop .business h4 span::after {
    width: 13vw;
    height: 1.8vw;
  }
}

#indexTop .business h4 span::after {
  transform: rotate(180deg);
  margin-left: 10px;
}

#indexTop .business .columnLayout {
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 750px) {
  #indexTop .business .columnLayout {
    display: block;
  }
}

#indexTop .business .columnLayout img {
  width: 40%;
}

#indexTop .business .columnLayout img:nth-child(2) {
  width: 35%;
}

#indexTop .business .columnLayout img:nth-child(3) {
  width: 87.4%;
  margin: auto;
}

#indexTop .business .columnLayout img:nth-child(4) {
  width: 74%;
  margin: 12% auto 0;
}

#indexTop .access {
  padding-bottom: 5.85%;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #indexTop .access {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .access {
    padding-bottom: 0;
  }
}

#indexTop .access::before, #indexTop .access:after {
  content: "";
  position: absolute;
  bottom: 0;
  z-index: -1;
  background-image: url(/images/index/bg_pattern_blue.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 46vw;
  max-width: 628px;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5) {
  #indexTop .access::before, #indexTop .access:after {
    background-image: url(/images/index/bg_pattern_blue@2x.png);
  }
}

@media screen and (max-width: 750px) {
  #indexTop .access::before, #indexTop .access:after {
    width: 93.2vw;
    height: 62.4vw;
    max-width: none;
    max-height: none;
    left: 0;
    right: inherit;
  }
}

#indexTop .access::before {
  left: 4%;
  height: 19vw;
  max-height: 262px;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 750px) {
  #indexTop .access::before {
    top: 56.8vw;
  }
}

#indexTop .access::after {
  right: 5%;
  height: 30vw;
  max-height: 419px;
}

@media screen and (max-width: 750px) {
  #indexTop .access::after {
    bottom: 56.8vw;
  }
}

#indexTop .accessLayout {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

#indexTop .accessLayout::after {
  content: "";
  background-image: url(/images/index/line_left_long.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 10px;
  position: absolute;
  bottom: 120px;
  right: 0;
}

@media screen and (max-width: 750px) {
  #indexTop .accessLayout::after {
    width: 14vw;
    bottom: 63vw;
  }
}

#indexTop .accessVisual {
  flex-basis: 50%;
}

@media screen and (max-width: 750px) {
  #indexTop .accessVisual {
    flex-basis: 100%;
  }
}

#indexTop .accessMap {
  flex-basis: 50%;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 750px) {
  #indexTop .accessMap {
    flex-basis: 100%;
    padding-top: 56.8%;
    order: 1;
  }
}

#indexTop .accessMap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#indexTop .accessContent {
  flex-basis: 100%;
}

#indexTop .access .secTit {
  transform: translateY(-35%);
}

#indexTop .access h3 {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  font-weight: bold;
  line-height: 2.67;
  letter-spacing: 0.9px;
  margin-bottom: 4.6%;
}

@media screen and (min-width: 1366px) {
  #indexTop .access h3 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .access h3 {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    line-height: 1.71;
    letter-spacing: 1.4px;
    margin: 9% 0 7%;
  }
}

#indexTop .access h3 span {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  letter-spacing: 0.7px;
}

@media screen and (min-width: 1366px) {
  #indexTop .access h3 span {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .access h3 span {
    font-size: 2.6rem;
    font-size: 3.46667vw;
  }
}

#indexTop .access dl {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  line-height: 2.14286;
}

@media screen and (min-width: 1366px) {
  #indexTop .access dl {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #indexTop .access dl {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    line-height: 1.71;
    display: block;
  }
}

#indexTop .access dl dt {
  flex-basis: 7%;
  max-width: 7%;
  font-weight: bold;
  letter-spacing: 0.7px;
  border-right: solid 1px;
}

@media screen and (max-width: 750px) {
  #indexTop .access dl dt {
    max-width: none;
    border: none;
  }
}

#indexTop .access dl dd {
  flex-basis: 42%;
  max-width: 42%;
  letter-spacing: 0.7px;
  padding-left: 30px;
}

@media screen and (max-width: 750px) {
  #indexTop .access dl dd {
    max-width: none;
    padding: 0;
    margin-bottom: 7.4%;
  }
}

#indexTop .access dl dd p:first-of-type {
  margin-bottom: 10px;
}

#indexTop .access .btnLayout {
  margin: 10px 0;
}

@media screen and (max-width: 750px) {
  #indexTop .access .btnLayout {
    margin: 2% 0 0;
  }
}

#lowerPage .mainVisual {
  margin: 80px auto 120px;
  width: 90%;
  height: 18vw;
  max-height: 250px;
  background-image: url(/images/lower/lower_visual.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5) {
  #lowerPage .mainVisual {
    background-image: url(/images/lower/lower_visual@2x.png);
  }
}

@media screen and (max-width: 750px) {
  #lowerPage .mainVisual {
    margin: 80px auto 8%;
    width: 94%;
    height: 40vw;
  }
}

#lowerPage .lowerTit {
  width: 90%;
  max-width: 1080px;
  margin: auto;
  position: absolute;
  top: 30%;
  left: 5%;
  right: 0;
}

@media screen and (max-width: 750px) {
  #lowerPage .lowerTit {
    top: 20%;
  }
}

#lowerPage .lowerTit h2 {
  font-family: BebasNeue;
  font-size: 5rem;
  font-size: 3.66032vw;
  line-height: 1.2;
  letter-spacing: 4px;
  color: #006cb6;
  display: inline-block;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #lowerPage .lowerTit h2 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 750px) {
  #lowerPage .lowerTit h2 {
    font-size: 6rem;
    font-size: 8vw;
  }
}

#lowerPage .lowerTit h2 span {
  font-weight: 300;
}

#lowerPage .lowerTit h2::after {
  content: "";
  width: 100vw;
  height: 1px;
  background-color: #000;
  position: absolute;
  bottom: -3vw;
  right: 10%;
}

@media screen and (max-width: 750px) {
  #lowerPage .lowerTit h2::after {
    bottom: -8vw;
  }
}

#lowerPage .lowerTit p {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  line-height: 1.75;
  letter-spacing: 1.4px;
  color: #006cb6;
  padding-left: 1.5%;
}

@media screen and (min-width: 1366px) {
  #lowerPage .lowerTit p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #lowerPage .lowerTit p {
    font-size: 2.3rem;
    font-size: 3.06667vw;
    padding-left: 2.5%;
  }
}

#lowerPage .content {
  max-width: 1080px;
  margin: auto;
  width: 80%;
}

@media screen and (max-width: 750px) {
  #lowerPage .content {
    width: 90%;
  }
}

#lowerPage .content h3 {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: 0.9px;
  margin: 90px 0 40px;
}

@media screen and (min-width: 1366px) {
  #lowerPage .content h3 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 750px) {
  #lowerPage .content h3 {
    font-size: 3.2rem;
    font-size: 4.26667vw;
    margin: 12% 0 6%;
  }
}

#lowerPage .content h4 {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: 0.8px;
  margin: 40px 0 20px;
}

@media screen and (min-width: 1366px) {
  #lowerPage .content h4 {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 750px) {
  #lowerPage .content h4 {
    font-size: 3rem;
    font-size: 4vw;
    margin: 6% 0 3%;
  }
}

#lowerPage .content p {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  line-height: 2;
  letter-spacing: 0.7px;
  margin-bottom: 20px;
}

@media screen and (min-width: 1366px) {
  #lowerPage .content p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #lowerPage .content p {
    font-size: 2.8rem;
    font-size: 3.73333vw;
    margin-bottom: 3%;
  }
}

#lowerPage .content ol li {
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-size: 1.02489vw;
  letter-spacing: 0.7px;
}

@media screen and (min-width: 1366px) {
  #lowerPage .content ol li {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #lowerPage .content ol li {
    font-size: 2.8rem;
    font-size: 3.73333vw;
  }
}

#lowerPage .content dl {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  letter-spacing: 0.7px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 1366px) {
  #lowerPage .content dl {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 750px) {
  #lowerPage .content dl {
    display: block;
    font-size: 2.8rem;
    font-size: 3.73333vw;
  }
}

#lowerPage .content dl dt {
  flex-basis: 12%;
  font-weight: bold;
}

@media screen and (max-width: 750px) {
  #lowerPage .content dl dt {
    border-bottom: #000 1px solid;
    padding-bottom: 2%;
  }
}

#lowerPage .content dl dd {
  flex-basis: 88%;
  margin-bottom: 2%;
  padding-left: 2%;
  border-left: #000 1px solid;
}

@media screen and (max-width: 750px) {
  #lowerPage .content dl dd {
    border: none;
    margin-bottom: 5%;
    padding: 3% 0;
  }
}

#lowerPage .content .bold {
  font-weight: bold;
}

#lowerPage .content .companyName {
  text-align: right;
  margin-top: 90px;
  padding: 0;
}

@media screen and (max-width: 750px) {
  #lowerPage .content .companyName {
    font-size: 2.4rem;
    font-size: 3.2vw;
    margin-top: 12%;
  }
}

#lowerPage.privacyPolicy .content h3, #lowerPage.solicitationPolicy .content h3 {
  line-height: 3;
  margin: 40px 0 20px;
}

@media screen and (max-width: 750px) {
  #lowerPage.privacyPolicy .content h3, #lowerPage.solicitationPolicy .content h3 {
    line-height: 1.75;
    margin: 9% 0 3%;
  }
}

#lowerPage.privacyPolicy .content p:first-of-type, #lowerPage.solicitationPolicy .content p:first-of-type {
  padding: 0;
}

.complaintHandling,
.privacyPolicy,
.solicitationPolicy {
  counter-reset: number 0;
}

.complaintHandling .content h3, .complaintHandling .content h4, .complaintHandling .content p, .complaintHandling .content ol, .complaintHandling .content dl,
.privacyPolicy .content h3,
.privacyPolicy .content h4,
.privacyPolicy .content p,
.privacyPolicy .content ol,
.privacyPolicy .content dl,
.solicitationPolicy .content h3,
.solicitationPolicy .content h4,
.solicitationPolicy .content p,
.solicitationPolicy .content ol,
.solicitationPolicy .content dl {
  padding-left: 5.6%;
}

@media screen and (max-width: 750px) {
  .complaintHandling .content h3, .complaintHandling .content h4, .complaintHandling .content p, .complaintHandling .content ol, .complaintHandling .content dl,
  .privacyPolicy .content h3,
  .privacyPolicy .content h4,
  .privacyPolicy .content p,
  .privacyPolicy .content ol,
  .privacyPolicy .content dl,
  .solicitationPolicy .content h3,
  .solicitationPolicy .content h4,
  .solicitationPolicy .content p,
  .solicitationPolicy .content ol,
  .solicitationPolicy .content dl {
    padding-left: 10%;
  }
}

.complaintHandling .content h3,
.privacyPolicy .content h3,
.solicitationPolicy .content h3 {
  position: relative;
}

.complaintHandling .content h3::before,
.privacyPolicy .content h3::before,
.solicitationPolicy .content h3::before {
  counter-increment: number;
  content: counter(number);
  font-size: 4.8rem;
  font-size: 3.51391vw;
  font-weight: normal;
  line-height: 0.83;
  letter-spacing: 2.4px;
  color: #006cb6;
  position: absolute;
  top: 10%;
  left: 0;
}

@media screen and (min-width: 1366px) {
  .complaintHandling .content h3::before,
  .privacyPolicy .content h3::before,
  .solicitationPolicy .content h3::before {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 750px) {
  .complaintHandling .content h3::before,
  .privacyPolicy .content h3::before,
  .solicitationPolicy .content h3::before {
    font-size: 6rem;
    font-size: 8vw;
    top: 5%;
  }
}

.complaintHandling .content h3::after,
.privacyPolicy .content h3::after,
.solicitationPolicy .content h3::after {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #006cb6;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  margin: auto;
}

@media screen and (max-width: 750px) {
  .complaintHandling .content h3::after,
  .privacyPolicy .content h3::after,
  .solicitationPolicy .content h3::after {
    left: 7.5%;
  }
}

.antiSocial .content ol {
  counter-reset: number 0;
  padding-top: 20px;
}

@media screen and (max-width: 750px) {
  .antiSocial .content ol {
    padding-top: 10%;
  }
}

.antiSocial .content ol li {
  padding-bottom: 20px;
  line-height: 1.5;
  position: relative;
  padding-left: 3%;
}

@media screen and (max-width: 750px) {
  .antiSocial .content ol li {
    padding-left: 7%;
    padding-bottom: 5%;
    line-height: 1.75;
  }
}

@media screen and (min-width: 751px) and (max-width: 1024px) {
  .antiSocial .content ol li {
    line-height: 1;
  }
}

.antiSocial .content ol li::before {
  counter-increment: number;
  content: counter(number) ".";
  font-size: 2.1rem;
  font-size: 1.53734vw;
  color: #006cb6;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1;
}

@media screen and (min-width: 1366px) {
  .antiSocial .content ol li::before {
    font-size: 2.1rem;
  }
}

@media screen and (max-width: 750px) {
  .antiSocial .content ol li::before {
    font-size: 4.2rem;
    font-size: 5.6vw;
    top: 3%;
  }
}
