.about {
  margin-top: -80px;
  padding-top: 180px;
  position: relative;
  @include mq(md) {
    margin-top: 0;
  }
  &::after {
    content: "";
    background-image: url(/images/index/line_right_long.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100px;
    height: 10px;
    position: absolute;
    top: 280px;
    right: 3%;
    @include mq(md) {
      width: 14vw;
      top: 200px;
    }
  }
  h3 {
    @include fz_vw_pc(24);
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.2px;
    margin-bottom: 1.85%;
    @include mq(md) {
      @include fz_vw_md(36);
      margin-bottom: 8%;
    }
  }
  &Message {
    position: relative;
    margin-top: -22%;
    padding: 28% 0 19%;
    @include mq(fixed) {
      margin-top: -300px;
      padding: 380px 0 260px;
    }
    @include mq(md) {
      margin-top: -90%;
      padding: 100% -0px 30%;
    }
    &::before,
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
    }
    &::before {
      background: linear-gradient(175deg,#f8f8f8 0,#f8f8f8 83%,transparent 83%,transparent 100%);
      top: 0;
      left: 0;
      width: 94.15%;
      height: 100%;
      @include mq(md) {
        width: 100%;
        background: linear-gradient(170deg,#f8f8f8 0,#f8f8f8 91%,transparent 91%,transparent 100%);
      }
    }
    &::after {
      background-image: url(/images/index/bg_message.png);
      @include media-retina {
        background-image: url(/images/index/bg_message@2x.png);
      }
      background-repeat: no-repeat;
      background-size: cover;
      top: 0;
      bottom: 0;
      margin: auto;
      left: -10%;
      width: 52vw;
      height: 52vw;
      max-width: 716px;
      max-height: 716px;
      @include mq(md) {
        width: 95vw;
        height: 95vw;
        top: 50vw;
        margin: 0;
      }
    }
    p {
      @include fz_vw_pc(14);
      line-height: 3.3;
      letter-spacing: 0.7px;
      @include mq(md) {
        @include fz_vw_md(28);
        line-height: 2;
        letter-spacing: 1.4px;
      }
    }
    .name {
      @include fz_vw_pc(16);
      line-height: 2;
      letter-spacing: 0.8px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: 3%;
      @include mq(md) {
        @include fz_vw_md(28);
        margin-top: 6.4%;
      }
      span {
        width: 11%;
        max-width: 116px;
        margin-left: 15px;
        @include mq(md) {
          width: 21.6vw;
          margin-left: 6%;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &Vision {
    position: relative;
    padding-bottom: 16%;
    @include mq(fixed) {
      padding-bottom: 230px;
    }
    @include mq(md) {
      padding-bottom: 0;
    }
    &::before {
      content: "";
      background-image: url(/images/index/bg_vision.jpg);
      @include media-retina {
        background-image: url(/images/index/bg_vision@2x.jpg);
      }
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      @include mq(md) {
        height: 20%;
      }
    }
    .visionTxt {
      @include fz_vw_pc(30);
      line-height: 1.67;
      letter-spacing: 3px;
      text-align: center;
      color: #006cb6;
      @include mq(md) {
        @include fz_vw_md(40);
      }
      span {
        display: inline-block;
        padding-right: 50px;
        @include mq(md) {
          padding: 0;
          line-height: 1.5;
        }
        &:last-child {
          padding-right: 0;
          padding-left: 50px;
          @include mq(md) {
            padding: 0;
          }
        }
      }
      &::before,
      &::after {
        content: "";
        background-size: contain;
        background-repeat: no-repeat;
        vertical-align: middle;
        display: inline-block;
        width: 60px;
        height: 10px;
        @include mq(md) {
          display: none;
        }
      }
      &::before {
        background-image: url(/images/index/line_left.svg);
      }
      &::after {
        background-image: url(/images/index/line_right.svg);
      }
    }
    .en {
      font-family: $BebasNeue;
      @include fz_vw_pc(16);
      font-weight: bold;
      line-height: 3.13;
      letter-spacing: 2.4px;
      text-align: center;
      color: #006cb6;
      margin-bottom: 7.4%;
      @include mq(md) {
        @include fz_vw_md(24);
        margin-bottom: 13.3%;
      }
    }
    .visionColumn {
      justify-content: center;
      @include mq(md) {
        display: block;
      }
      .col3 {
        padding: 0 3%;
        border-right: solid 1px #000;
        @include mq(md) {
          border: none;
          padding: 0;
          margin-bottom: 13%;
        }
        &:last-child {
          border: none;
        }
      }
      p {
        @include fz_vw_pc(14);
        line-height: 2.14;
        letter-spacing: 1.4px;
        @include mq(md) {
          @include fz_vw_md(28);
          padding: 0 7%;
          line-height: 2;
        }
      }
      .visionTit {
        @include fz_vw_pc(24);
        font-weight: bold;
        line-height: 2.08;
        letter-spacing: 2.4px;
        text-align: center;
        margin: 4% 0 10%;
        @include mq(md) {
          @include fz_vw_md(34);
          margin: 7.4% 0 3%;
        }
        span {
          font-family: $BebasNeue;
          @include fz_vw_pc(14);
          line-height: 1;
          letter-spacing: 1.4px;
          display: block;
          @include mq(md) {
            @include fz_vw_md(24);
          }
        }
      }
    }
  }
  &Philosophy {
    margin-bottom: 11%;
    @include mq(fixed) {
      margin-bottom: 150px;
    }
    @include mq(md) {
      margin: 0;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        background-image: url(/images/index/bg_pattern_beige.png);
        @include media-retina {
          background-image: url(/images/index/bg_pattern_beige@2x.png);
        }
        background-repeat: no-repeat;
        background-size: contain;
        width: 94vw;
        height: 63vw;
        bottom: -2%;
        left: 0;
      }
    }
    &.open {
      &::after {
        display: none;
        transition: .5s;
      }
    }
    &Head {
      position: relative;
      margin-top: -22.7%;
      padding: 22% 0 4.4%;
      overflow: hidden;
      @include mq(fixed) {
        margin-top: -310px;
        padding: 300px 0 60px;
      }
      @include mq(md) {
        margin-top: -40%;
        padding: 75% -0px 12%;
      }
      &::before,
      &:after {
        content: "";
        position: absolute;
        z-index: -1;
      }
      &::before {
        background: linear-gradient(-5deg,#f7f6f0 0,#f7f6f0 70%,transparent 70%,transparent 100%);
        top: 0;
        right: 0;
        width: 94.15%;
        height: 100%;
        @include mq(md) {
          background: linear-gradient(-10deg,#f7f6f0 0,#f7f6f0 70%,transparent 70%,transparent 100%);
          width: 100%;
        }
      }
      &::after {
        background-image: url(/images/index/bg_pattern_beige.png);
        @include media-retina {
          background-image: url(/images/index/bg_pattern_beige@2x.png);
        }
        background-repeat: no-repeat;
        background-size: contain;
        width: 60vw;
        height: 40vw;
        max-width: 794px;
        max-height: 532px;
        bottom: -30%;
        left: 6%;
        @include mq(md) {
          width: 94vw;
          height: 63vw;
          bottom: 25%;
          left: 0;
        }
      }
      h4 {
        @include fz_vw_pc(18);
      }
    }
    h4 {
      font-weight: bold;
      line-height: 2.25;
      letter-spacing: 0.8px;
      margin-bottom: 20px;
      @include mq(md) {
        @include fz_vw_md(32);
        margin-bottom: 3%;
      }
    }
    p {
      @include fz_vw_pc(14);
      line-height: 2.14;
      letter-spacing: 0.7px;
      @include mq(md) {
        @include fz_vw_md(28);
        line-height: 1.71;
        letter-spacing: 1.4px;
      }
    }
    &Btn {
      margin: 50px auto 0;
      text-align: center;
      width: 11vw;
      max-width: 160px;
      position: relative;
      @include mq(md) {
        width: 36vw;
        max-width: none;
      }
      &::before,
      &::after {
        content: "";
        width: 100px;
        height: 1px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: #000;
        display: block;
        @include mq(md) {
          width: 60%;
        }
      }
      &::before {
        left: -120px;
        @include mq(md) {
          left: -75%;
        }
      }
      &::after {
        right: -120px;
        @include mq(md) {
          right: -75%;
        }
      }
      a {
        font-family: $BebasNeue;
        @include fz_vw_pc(16);
        letter-spacing: .8px;
        color: #fff;
        background-color: #000;
        padding: 10px 0;
        cursor: pointer;
        outline: none;
        display: block;
        transition: .5s;
        @include mq(md) {
          @include fz_vw_md(40);
          padding: 3% 14%;
        }
        &.hover {
          background-color: #555;
        }
      }
    }
    &Detail {
      position: relative;
      padding: 1.5% 0 4.4%;
      @include mq(fixed) {
        padding: 20px 0 60px;
      }
      @include mq(md) {
        padding: 0 0 35%;
      }
      &::before,
      &:after {
        content: "";
        position: absolute;
        z-index: -1;
      }
      &::before {
        background: #f7f6f0;
        bottom: 0;
        right: 0;
        width: 94.15%;
        height: 100%;
        @include mq(md) {
          width: 100%;
        }
      }
      &::after {
        background-image: url(/images/index/bg_pattern_beige.png);
        @include media-retina {
          background-image: url(/images/index/bg_pattern_beige@2x.png);
        }
        background-repeat: no-repeat;
        background-size: contain;
        width: 47vw;
        height: 31vw;
        max-width: 637px;
        max-height: 426px;
        bottom: 7%;
        right: 2%;
        @include mq(md) {
          width: 94vw;
          height: 63vw;
          bottom: 60%;
          left: 0;
        }
      }
      &.open {
        &::after {
          display: none;
        }
      }
      h4 {
        @include fz_vw_pc(16);
        margin-bottom: 10px;
        margin-top: 20px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}