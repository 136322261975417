@charset "utf-8";

.columnLayout {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @for $i from 1 through 8 {
    $width: percentage(1 / $i);
    .col#{$i} {
      flex-basis: floor($width);
      max-width: floor($width);
      @include mq(md) {
        max-width: none;
      }
    }
  }
}

