.business {
  position: relative;
  padding: 6.6% 0 5.85%;
  margin-bottom: 11%;
  @include mq(fixed) {
    padding: 90px 0 80px;
    margin-bottom: 150px;
  }
  @include mq(md) {
    padding: 27% 0 40%;
    margin: 0 0 -5%;
  }
  &::before {
    content: "";
    background: linear-gradient(5deg,#f7f6f0 0,#f7f6f0 80%,transparent 80%,transparent 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 94.15%;
    height: 100%;
    z-index: -1;
    @include mq(md) {
      background: linear-gradient(10deg,#f7f6f0 0,#f7f6f0 90%,transparent 90%,transparent 100%);
      width: 100%;
    }
  }
  h3 {
    @include fz_vw_pc(24);
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.2px;
    margin: 7.4% 0 1.85%;
    @include mq(md) {
      @include fz_vw_md(36);
      line-height: 1.67;
      letter-spacing: 1.8px;
      margin: 15% 0 5%;
    }
  }
  p {
    @include fz_vw_pc(14);
    @include mq(md) {
      @include fz_vw_md(20);
    }
  }
  .container {
    p {
      line-height: 3.29;
      letter-spacing: 0.7px;
      @include mq(md) {
        @include fz_vw_md(28);
        line-height: 2;
        letter-spacing: 1.4px;
      }
    }
  }
  h4 {
    @include fz_vw_pc(14);
    font-weight: 900;
    line-height: 2.14;
    letter-spacing: 0.7px;
    text-align: center;
    color: #006cb6;
    margin: 4.6% 0;
    @include mq(md) {
      @include fz_vw_md(28);
      line-height: 1.71;
      letter-spacing: 1.4px;
      font-weight: normal;
      margin: 10% 0;
    }
    span {
      display: block;
      @include fz_vw_pc(24);
      line-height: 1.25;
      letter-spacing: 1.2px;
      @include mq(md) {
        @include fz_vw_md(28);
        line-height: 1.71;
        letter-spacing: 1.4px;
        font-weight: bold;
      }
      &::before,
      &::after {
        content: "";
        background-size: contain;
        background-repeat: no-repeat;
        vertical-align: middle;
        display: inline-block;
        width: 60px;
        height: 10px;
        background-image: url(/images/index/line_left.svg);
        margin-right: 10px;
        @include mq(md) {
          width: 13vw;
          height: 1.8vw;
        }
      }
      &::after {
        transform: rotate(180deg);
        margin-left: 10px;
      }
    }
  }
  .columnLayout {
    justify-content: space-around;
    align-items: center;
    @include mq(md) {
      display: block;
    }
    img {
      width: 40%;
      &:nth-child(2) {
        width: 35%;
      }
      &:nth-child(3) {
        width: 87.4%;
        margin: auto;
      }
      &:nth-child(4) {
        width: 74%;
        margin: 12% auto 0;
      }
    }
  }
}