@charset "utf-8";

// ブレークポイント処理
// 使用例：@include mq(md) {}
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// clearfix
// 使用例：@include clearfix;
@mixin clearfix {
  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

// PCサイズ Retina画像出し分け用
// 使用例：@include media-retina {background-image: url("~~~@2x.jpg");}
@mixin media-retina() {
  @media (-webkit-min-device-pixel-ratio: 1.5),
    (min-resolution: 1.5) {
      @content;
  }
}

/* ================================================================================
フォントサイズ VW設定
================================================================================ */

/*PC設定*/
@function get_vw_pc($size, $viewport:1366) {
  @return (100 / $viewport) * $size * 1vw;
}
@mixin fz_vw_pc($font_size:10) {
  font-size: $font_size * 0.1rem;
  font-size: get_vw_pc($font_size);
  @include mq(fixed) {
    font-size: $font_size * 0.1rem;
  }
}

/*タブレット設定*/
@function get_vw_tab($size, $viewport:768) {
  @return (100 / $viewport) * $size * 1vw;
}
@mixin fz_vw_tab($font_size:10) {
  font-size: $font_size * 0.1rem;
  font-size: get_vw_tab($font_size);
}

/*SP設定*/
@function get_vw_md($size, $viewport:750) {
  @return (100 / $viewport) * $size * 1vw;
}
@mixin fz_vw_md($font_size:10) {
  font-size: $font_size * 0.1rem;
  font-size: get_vw_md($font_size);
}

// line-height 計算
@mixin fz_lh($font_size:0, $line_height:0) {
  line-height: $line_height / $font_size;
}

// フォント周り一括指定 true：文字間広め
// @include fz_lh(20,35,true);
//@mixin fz_lh($font_size:0, $line_height:0, $is_wide:false) {
//  font-size: $font_size * 0.1rem;
//  line-height: $line_height / $font_size;
//  @if($is_wide==true){
//    letter-spacing: ($font_size / 10) * 1px;
//  } 
//  @else {
//    letter-spacing: (($font_size / 10) * 1px)/2;
//  }
//}

// @include absolute(0,0,0,0);
// @include absolute($top:0, $bottom:0);
@mixin absolute($top:null, $right:null, $bottom:null, $left:null){
  position: absolute;
  @if $top != null{
    top: $top;
  }
  @if $right != null{
    right: $right;
  }
  @if $bottom != null{
    bottom: $bottom;
  }
  @if $left != null{
    left: $left;
  }
}