@charset "utf-8";
/* ================================================================================
値の定義
================================================================================ */

// フォントサイズ
$mainRemFontSize: 62.5%; // =10pxに相当
// 基本フォントサイズ(SP)
$mainFontSpSize: 100%;
// 基本横幅
$basicWidth: 1020px;

// 基本カラー
$basicColor: #555;
$basicWhiteColor: #fff;
$keyColor: #c00;
$borderColor: #999;
$thinBorderColor: #ccc;
$bgColor: #f0f0f0;
$mainColor: #98c043;

// 基本フォント
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
$base-font:"Noto Sans JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;

$yakuhan:YakuHanJP_Noto,
"Noto Sans JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;

$BebasNeue: BebasNeue;

// ブレークポイント定義
$breakpoints: (
"md": "screen and (max-width : 750px)",
"tab": "screen and (min-width : 751px) and (max-width : 1024px)",
"pc": "screen and (min-width : 1025px) and (max-width : 1365px)",
"fixed": "screen and (min-width : 1366px)",
) !default;

/* ================================================================================
importファイル
================================================================================ */

// BebasNeue
@import "lib/bebas-neue";

// @mixin定義
@import "foundation/mixin";

// @extend定義
//@import "foundation/extend";

// リセット
@import "foundation/reset";

// 印刷
//@import "foundation/print";

// 基本 
@import "layout/bace";

// ヘッダー
@import "layout/header";

// フッター
@import "layout/footer";

// コンポーネント（共通パーツ）
@import "component/title";
@import "component/animation";
@import "component/button";
@import "component/common";


// トップページ
@import "page/index";

// 下層ページ
@import "page/lower";