@charset "utf-8";

/* ================================================================================
フッター
================================================================================ */

footer#footer {
  max-width: 1080px;
  margin: auto;
  width: 80%;
  @include mq(md) {
    width: 90%;
  }
  .siteMap {
    border: solid #006cb6;
    border-width: 1px 0;
    padding-top: 30px;
    @include mq(md) {
      padding-top: 20px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      @include mq(md) {
        display: block;
      }
      li {
        @include fz_vw_pc(14);
        letter-spacing: 0.7px;
        position: relative;
        padding-left: 25px;
        text-indent: -25px;
        margin-bottom: 30px;
        @include mq(md) {
          @include fz_vw_md(24);
          width: 100% !important;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 890px) and (min-width: 751px) {
          width: 50% !important;
        }
        &:before {
          content: "";
          width: 15px;
          height: 2px;
          background-color: #006cb6;
          display: inline-block;
          vertical-align: super;
          margin-right: 10px;
        }
        &:nth-of-type(3n-2),
        &:nth-of-type(3n-1){
          width: 30%;
        }
        &:nth-of-type(3n) {
          width: 40%;
        }
      }
    }
  }
  .copyRight {
    background-color: #fff;
    padding: 40px 0;
    @include mq(md) {
      padding: 6.6% 0;
    }
    p {
      @include fz_vw_pc(12);
      line-height: 2;
      letter-spacing: 1.2px;
      text-align: center;
      @include mq(md) {
        @include fz_vw_md(20);
      }
    }
  }
}