.company {
  position: relative;
  &::before {
    @include mq(md) {
      content: "";
      background-image: url(/images/index/line_left_long.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 14vw;
      height: 10px;
      position: absolute;
      top: 64vw;
      right: 3%;
    }
  }
  &::after {
    content: "";
    background-image: url(/images/index/line_right_long.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100px;
    height: 10px;
    position: absolute;
    bottom: 2%;
    left: 3%;
    @include mq(md) {
      width: 14vw;
      bottom: 20vw;
      left: 5.3%;
    }
  }
  &Visual {
    img {
      @include mq(md) {
        height: 56vw;
      }
    }
  }
  .secTit {
    transform: translateY(-35%);
  }
  &Profile {
    margin-top: 30px;
    dl {
      display: flex;
      flex-wrap: wrap;
      @include fz_vw_pc(14);
      line-height: 2.14;
      letter-spacing: 1.4px;
      @include mq(md) {
        @include fz_vw_md(28);
        display: block;
        line-height: 1.71;
        letter-spacing: 0.4px;
      }
      dt {
        flex-basis: 12%;
        font-weight: bold;
        color: #006cb6;
      }
      dd {
        flex-basis: 88%;
        padding-bottom: 3.16%;
        @include mq(md) {
          padding-bottom: 5.3%;
        }
        .btnLayout {
          padding-top: 1.6%;
          @include mq(md) {
            padding-top: 4%;
          }
        }
      }
    }
    .companyName {
      @include fz_vw_pc(18);
      font-weight: bold;
      line-height: 1.8;
      @include mq(md) {
        @include fz_vw_md(28);
      }
      span {
        @include fz_vw_pc(16);
        display: block;
        font-weight: 500;
        @include mq(md) {
          @include fz_vw_md(28);
        }
      }
    }
  }
  &Detail {
    position: relative;
    padding-top: 22%;
    margin-top: -11%;
    @include mq(fixed) {
      padding-top: 300px;
      margin-top: -150px;
    }
    @include mq(md) {
      padding: 32% 0 34.8%;
    }
    &::before {
      content: "";
      background: linear-gradient(-5deg, #edf3f9 0, #edf3f9 86%,transparent 86%,transparent 100%);
      position: absolute;
      top: 0;
      right: 0;
      width: 94.15%;
      height: 100%;
      z-index: -1;
      @include mq(md) {
        background: linear-gradient(-10deg, #edf3f9 0, #edf3f9 96%,transparent 96%,transparent 100%);
        width: 100%;
      }
    }
    h3 {
      @include fz_vw_pc(18);
      @include fz_lh(18,27);
      font-weight: bold;
      letter-spacing: 0.9px;
      color: #006cb6;
      text-indent: -10px;
      flex-basis: 100%;
      margin: 10% 0 4%;
      &:first-of-type {
        margin-top: 0;
      }
      @include mq(md) {
        @include fz_vw_md(30);
        margin: 7.5% 0 3%;
      }
      &.groupTit {
        @include mq(md) {
          &::before {
            content: "";
            background-image: url(/images/index/line_three.svg);
            background-size: contain;
            background-repeat: no-repeat;
            width: 18.8vw;
            height: 3.2vw;
            display: block;
            margin: 10vw auto;
          }
        }
      }
    }
    p {
      @include fz_vw_pc(14);
      line-height: 2.14;
      letter-spacing: 0.7px;
      @include mq(md) {
        @include fz_vw_md(26);
      }
    }
    .col2 {
      flex-basis: 46%;
      max-width: 46%;
      margin-bottom: 7.4%;
      padding-left: 10px;
      position: relative;
      @include mq(md) {
        flex-basis: 100%;
        max-width: none;
        margin-bottom: 0;
        &::after {
          content: "";
          background-image: url(/images/index/line_three.svg);
          background-size: contain;
          background-repeat: no-repeat;
          width: 18.8vw;
          height: 3.2vw;
          display: block;
          margin: 10vw auto;
        }
        &:last-of-type {
          &::after {
            display: none;
          }
        }
      }
    }
    .line {
      width: 1px;
      background-color: #000;
      margin-bottom: 7.4%;
    }
    .stockHolder {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 9%;
      @include mq(md) {
        display: block;
        padding-bottom: 4%;
      }
      p {
        flex-basis: 80%;
        max-width: 80%;
        border-right: 1px solid;
        padding-right: 2%;
        @include mq(md) {
          border: none;
          max-width: none;
        }
        span {
          display: block;
          @include fz_vw_pc(16);
          font-weight: bold;
          @include mq(md) {
            @include fz_vw_md(28);
          }
        }
      }
      .rate {
        flex-basis: 20%;
        max-width: 20%;
        line-height: 1.71;
        text-align: center;
        font-weight: bold;
        border: none;
        padding: 0;
        @include mq(md) {
          font-weight: normal;
          text-align: left;
          border-top: 1px solid;
          padding-top: 2%;
          margin-top: 2%;
          max-width: none;
        }
        span {
          @include fz_vw_pc(30);
          font-weight: normal;
          line-height: 1.4;
          letter-spacing: 3px;
          @include mq(md) {
            @include fz_vw_md(30);
            display: inline-block;
            margin-left: 10px;
          }
        }
      }
    }
    .small {
      @include fz_vw_pc(12);
      @include mq(md) {
        @include fz_vw_md(24);
      }
    }
    dl {
      display: flex;
      flex-wrap: wrap;
      dt {
        flex-basis: 30%;
        @include fz_vw_pc(16);
        font-weight: bold;
        @include fz_lh(16,41);
        letter-spacing: 0.7px;
        @include mq(md) {
          @include fz_vw_md(28);
          line-height: 1.71;
          letter-spacing: 1.4px;
        }
      }
      dd {
        flex-basis: 70%;
        @include fz_vw_pc(14);
        @include fz_lh(14,41);
        letter-spacing: 1.4px;
        @include mq(md) {
          @include fz_vw_md(28);
          line-height: 1.71;
        }
      }
    }
    ul {
      li {
        @include fz_vw_pc(14);
        line-height: 2.14;
        letter-spacing: 0.7px;
        margin-bottom: 6%;
        padding-left: 25px;
        text-indent: -25px;
        @include mq(md) {
          @include fz_vw_md(28);
          margin-bottom: 3%;
        }
        &:before {
          content: "";
          width: 15px;
          height: 2px;
          background-color: #006cb6;
          display: inline-block;
          vertical-align: super;
          margin-right: 10px;
        }
      }
    }
    .group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      img {
        margin-right: 30px;
        margin-bottom: 6%;
        max-width: 100px;
        @include mq(md) {
          margin: 4.5% auto 7.5%;
          width: 28%;
        }
      }
      .btnLayout {
        @include mq(md) {
          width: 100%;
        }
      }
    }
  }
}