@charset "utf-8";

/* ================================================================
ヘッダー
================================================================ */

.headerLayout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 100;
  background-color: #fff;
  padding: 0 4.4vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: .5s;
  @include mq(md) {
    padding: 0 5.3% 0 4%;
    height: 16vw;
  }
}

.headerLogo {
  width: 22.748815165876778%;
  max-width: 288px;
  @include mq(md) {
    width: 100%;
    height: 60%;
    max-width: 370px;
  }
  h1 {
    height: 100%;
    a {
      display: inline-block;
      height: 100%;
      img {
        height: 100%;
      }
    }
  }
}

.headerNav {
  background-color: #fff;
  z-index: -1;
  transition: .7s ease-in-out;
  @include mq(md) {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 16vw;
    height: auto;
    transform: translateY(-100%);
    opacity: 0;
  }
  ul {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    @include mq(md) {
      display: block;
      padding: 0 5.3%;
    }
    li {
      font-family: $BebasNeue;
      @include fz_vw_pc(20);
      line-height: 1.75;
      letter-spacing: 2px;
      padding-right: 25px;
      &:last-child {
        padding: 0;
        @include mq(md) {
          padding-bottom: 25px;
        }
      }
      @include mq(md) {
        @include fz_vw_md(50);
        padding-bottom: 20px;
        opacity: 0;
      }
      a {
        display: block;
        cursor: pointer;
      }
      span {
        @include fz_vw_md(20);
        font-weight: 300;
        line-height: 1.45;
        color: #000000;
      }
    }
  }
  &.show {
    transform: translateY(0);
    opacity: 1;
    li {
      opacity: 1;
      transition: 2.5s;
      @for $i from 1 through 6 {
        $second: (.3 + ($i / 5));
        &:nth-child(#{$i}) {
          transition-delay: #{$second}s;
        }
      }
    }
  }
}

.headerNavBtn {
  position: relative;
  width: 32px;
  height: 24px;
  cursor: pointer;
  @include mq(md) {
    width: 11.1%;
    height: 40%;
  }
  span {
    display: inline-block;
    transition: .4s;
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #000;
    transition: .3s ease-in-out;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 11px;
      @include mq(md) {
        top: 49%;
      }
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
  &.open {
    span {
      &:nth-of-type(1) {
        transform: translateY(11px) rotate(-135deg);
        @include mq(md) {
          transform: translateY(3vw) rotate(-135deg);
        }
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-11px) rotate(135deg);
        @include mq(md) {
          transform: translateY(-3vw) rotate(135deg);
        }
      }
    }
  }
}