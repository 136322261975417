#lowerPage {
  .mainVisual {
    margin: 80px auto 120px;
    width: 90%;
    height: 18vw;
    max-height: 250px;
    background-image: url(/images/lower/lower_visual.png);
    @include media-retina {
      background-image: url(/images/lower/lower_visual@2x.png);
    }
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    @include mq(md) {
      margin: 80px auto 8%;
      width: 94%;
      height: 40vw;
    }
  }
  .lowerTit {
    width: 90%;
    max-width: 1080px;
    margin: auto;
    position: absolute;
    top: 30%;
    left: 5%;
    right: 0;
    @include mq(md) {
      top: 20%;
    }
    h2 {
      font-family: $BebasNeue;
      @include fz_vw_pc(50);
      line-height: 1.2;
      letter-spacing: 4px;
      color: #006cb6;
      display: inline-block;
      position: relative;
      @include mq(md) {
        @include fz_vw_md(60);
      }
      span {
        font-weight: 300;
      }
      &::after {
        content: "";
        width: 100vw;
        height: 1px;
        background-color: #000;
        position: absolute;
        bottom: -3vw;
        right: 10%;
        @include mq(md) {
          bottom: -8vw;
        }
      }
    }
    p {
      @include fz_vw_pc(14);
      line-height: 1.75;
      letter-spacing: 1.4px;
      color: #006cb6;
      padding-left: 1.5%;
      @include mq(md) {
        @include fz_vw_md(23);
        padding-left: 2.5%;
      }
    }
  }
  .content {
    max-width: 1080px;
    margin: auto;
    width: 80%;
    @include mq(md) {
      width: 90%;
    }
    h3 {
      @include fz_vw_pc(18);
      font-weight: bold;
      line-height: 1.75;
      letter-spacing: 0.9px;
      margin: 90px 0 40px;
      @include mq(md) {
        @include fz_vw_md(32);
        margin: 12% 0 6%;
      }
    }
    h4 {
      @include fz_vw_pc(16);
      font-weight: bold;
      line-height: 1.75;
      letter-spacing: 0.8px;
      margin: 40px 0 20px;
      @include mq(md) {
        @include fz_vw_md(30);
        margin: 6% 0 3%;
      }
    }
    p {
      @include fz_vw_pc(14);
      line-height: 2;
      letter-spacing: 0.7px;
      margin-bottom: 20px;
      @include mq(md) {
        @include fz_vw_md(28);
        margin-bottom: 3%;
      }
    }
    ol {
      li {
        margin-bottom: 10px;
        @include fz_vw_pc(14);
        letter-spacing: 0.7px;
        @include mq(md) {
          @include fz_vw_md(28);
        }
      }
    }
    dl {
      @include fz_vw_pc(14);
      letter-spacing: 0.7px;
      display: flex;
      flex-wrap: wrap;
      @include mq(md) {
        display: block;
        @include fz_vw_md(28);
      }
      dt {
        flex-basis: 12%;
        font-weight: bold;
        @include mq(md) {
          border-bottom: #000 1px solid;
          padding-bottom: 2%;
        }
      }
      dd {
        flex-basis: 88%;
        margin-bottom: 2%;
        padding-left: 2%;
        border-left: #000 1px solid;
        @include mq(md) {
          border: none;
          margin-bottom: 5%;
          padding: 3% 0;
        }
      }
    }
    .bold {
      font-weight: bold;
    }
    .companyName {
      text-align: right;
      margin-top: 90px;
      padding: 0;
      @include mq(md) {
        @include fz_vw_md(24);
        margin-top: 12%;
      }
    }
  }
  &.privacyPolicy,
  &.solicitationPolicy {
    .content {
      h3 {
        line-height: 3;
        margin: 40px 0 20px;
        @include mq(md) {
          line-height: 1.75;
          margin: 9% 0 3%;
        }
      }
      p {
        &:first-of-type {
          padding: 0;
        }
      }
    }
  }
}

.complaintHandling,
.privacyPolicy,
.solicitationPolicy {
  counter-reset: number 0;
  .content {
    h3, h4, p, ol, dl {
      padding-left: 5.6%;
      @include mq(md) {
        padding-left: 10%;
      }
    }
    h3 {
      position: relative;
      &::before {
        counter-increment: number;
        content: counter(number);
        @include fz_vw_pc(48);
        font-weight: normal;
        line-height: 0.83;
        letter-spacing: 2.4px;
        color: #006cb6;
        position: absolute;
        top: 10%;
        left: 0;
        @include mq(md) {
          @include fz_vw_md(60);
          top: 5%;
        }
      }
      &::after {
        content: "";
        width: 1px;
        height: 100%;
        background-color: #006cb6;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 4%;
        margin: auto;
        @include mq(md) {
          left: 7.5%;
        }
      }
    }
  }
}

.antiSocial {
  .content {
    ol {
      counter-reset: number 0;
      padding-top: 20px;
      @include mq(md) {
        padding-top: 10%;
      }
      li {
        padding-bottom: 20px;
        line-height: 1.5;
        position: relative;
        padding-left: 3%;
        @include mq(md) {
          padding-left: 7%;
          padding-bottom: 5%;
          line-height: 1.75;
        }
        @include mq(tab) {
          line-height: 1;
        }
        &::before {
          counter-increment: number;
          content: counter(number)'.';
          @include fz_vw_pc(21);
          color: #006cb6;
          position: absolute;
          top: 0;
          left: 0;
          line-height: 1;
          @include mq(md) {
            @include fz_vw_md(42);
            top: 3%;
          }
        }
      }
    }
  }
}