@charset "utf-8";

/* ======================================================================
基本設定
===================================================================== */

* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */

#responsibleCheck {
  width: 1px;
  height: 0; // 横幅768pxまで
  @include mq(md) {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */

.pcOnly {
  @include mq(md) {
    display: none !important;
  }
}

.spOnly {
  display: none !important;

  @include mq(md) {
    display: block !important;
  }
}

html {
  font-size: $mainRemFontSize;
}

body {
  position: relative;
  font-family: $base-font;
  font-size: 1.6rem;
  font-weight: normal;
  color: #000;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.fixedBody {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

main {
  overflow-x: hidden;
}

/* ハイトカラー消去
------------------------------------------------------------- */

.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
  article {
    section {
      display: block;
      width: 100%;
      position: relative;
      p {
        font-family: $yakuhan;
      }
    }
  }
}

a {
  color: #000;
}

img.ofi {
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'
}

/* 共通デザイン
------------------------------------------------------------- */

.pageTop {
  padding: 7.32% 0;
  display: flex;
  @include mq(fixed) {
    padding: 100px 0;
  }
  @include mq(md) {
    padding: 14% 0;
  }
  &Btn {
    margin: auto;
    cursor: pointer;
    height: 5.85vw;
    width: 5%;
    @include mq(fixed) {
      height: 80px;
    }
    @include mq(md) {
      height: 10vw;
    }
    img {
      transition: .5s;
      height: 100%;
      margin: auto;
    }
    &.hover {
      img {
        transform: translateY(-5px);
      }
    }
  }
}

.breadcrumb {
  max-width: 1080px;
  margin: 0 auto 20px;
  width: 80%;
  @include mq(md) {
    width: 90%;
  }
  ol {;
    position: relative;
    width: 100%;
    padding-left: 3.2rem;
    word-break: break-all;
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 14px;
      height: 17px;
      margin: auto;
      content: "";
      background-image: url(/images/icons/icon_home.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
    & > li {
      display: inline;
      @include fz_vw_pc(14);
      letter-spacing: 0.7px;
      @include mq(md) {
        @include fz_vw_md(24);
      }
      & + li::before {
        padding: 0 7px 0 5px;
        content: ">";
      }
    }
  }
}

/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */