.access {
  padding-bottom: 5.85%;
  position: relative;
  @include mq(fixed) {
    padding-bottom: 80px;
  }
  @include mq(md) {
    padding-bottom: 0;
  }
  &::before,
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    z-index: -1;
    background-image: url(/images/index/bg_pattern_blue.png);
    @include media-retina {
      background-image: url(/images/index/bg_pattern_blue@2x.png);
    }
    background-repeat: no-repeat;
    background-size: contain;
    width: 46vw;
    max-width: 628px;
    @include mq(md) {
      width: 93.2vw;
      height: 62.4vw;
      max-width: none;
      max-height: none;
      left: 0;
      right: inherit;
    }
  }
  &::before {
    left: 4%;
    height: 19vw;
    max-height: 262px;
    background-size: cover;
    background-position: center;
    @include mq(md) {
      top: 56.8vw;
    }
  }
  &::after {
    right: 5%;
    height: 30vw;
    max-height: 419px;
    @include mq(md) {
      bottom: 56.8vw;
    }
  }
  &Layout {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    &::after {
      content: "";
      background-image: url(/images/index/line_left_long.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 100px;
      height: 10px;
      position: absolute;
      bottom: 120px;
      right: 0;
      @include mq(md) {
        width: 14vw;
        bottom: 63vw;
      }
    }
  }
  &Visual {
    flex-basis: 50%;
    @include mq(md) {
      flex-basis: 100%;
    }
  }
  &Map {
    flex-basis: 50%;
    overflow: hidden;
    position: relative;
    @include mq(md) {
      flex-basis: 100%;
      padding-top: 56.8%;
      order: 1;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &Content {
    flex-basis: 100%;
  }
  .secTit {
    transform: translateY(-35%);
  }
  h3 {
    @include fz_vw_pc(18);
    font-weight: bold;
    line-height: 2.67;
    letter-spacing: 0.9px;
    margin-bottom: 4.6%;
    @include mq(md) {
      @include fz_vw_md(28);
      line-height: 1.71;
      letter-spacing: 1.4px;
      margin: 9% 0 7%;
    }
    span {
      @include fz_vw_pc(14);
      letter-spacing: 0.7px;
      @include mq(md) {
        @include fz_vw_md(26);
      }
    }
  }
  dl {
    @include fz_vw_pc(14);
    @include fz_lh(14,30);
    @include mq(md) {
      @include fz_vw_md(28);
      line-height: 1.71;
      display: block;
    }
    dt {
      flex-basis: 7%;
      max-width: 7%;
      font-weight: bold;
      letter-spacing: 0.7px;
      border-right: solid 1px;
      @include mq(md) {
        max-width: none;
        border: none;
      }
    }
    dd {
      flex-basis: 42%;
      max-width: 42%;
      letter-spacing: 0.7px;
      padding-left: 30px;
      @include mq(md) {
        max-width: none;
        padding: 0;
        margin-bottom: 7.4%;
      }
      p:first-of-type {
        margin-bottom: 10px;
      }
    }
  }
  .btnLayout {
    margin: 10px 0;
    @include mq(md) {
      margin: 2% 0 0;
    }
  }
}