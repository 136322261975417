//history
.history {
  position: relative;
  padding-top: 11%;
  @include mq(fixed) {
    padding-top: 150px;
  }
  @include mq(md) {
    padding-top: 0;
  }
  &Layout {
    position: relative;
    @include mq(md) {
      display: flex;
      flex-direction: column;
    }
    &::before,
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      background-image: url(/images/index/bg_pattern_blue.png);
      @include media-retina {
        background-image: url(/images/index/bg_pattern_blue@2x.png);
      }
      background-repeat: no-repeat;
      background-size: contain;
      width: 46vw;
      max-width: 628px;
      height: 30vw;
      max-height: 419px;
      @include mq(md) {
        width: 93.2vw;
        height: 62.4vw;
      }
    }
    &::before {
      top: 0;
      left: 5%;
      @include mq(md) {
        top: 50%;
        left: 0;
      }
    }
    &::after {
      top: -11vw;
      right: 2%;
      @include mq(fixed) {
        top: -150px;
      }
      @include mq(md) {
        top: -34.8vw;
        right: 0;
      }
    }
  }
  .secTit {
    @include mq(md) {
      transform: translateY(-35%);
      z-index: 10;
    }
  }
  &Txt {
    width: 60%;
    margin-top: 7.4%;
    @include fz_vw_pc(14);
    line-height: 3.29;
    letter-spacing: 0.7px;
    @include mq(md) {
      @include fz_vw_md(28);
      line-height: 2;
      letter-spacing: 1.4px;
      width: 100%;
      margin-top: 5%;
    }
    p {
      margin-bottom: 7.7%;
      @include mq(md) {
        margin-bottom: 6%;
      }
    }
  }
  &Img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 37.5%;
    @include mq(md) {
      position: static;
      order: -1;
      width: 100%;
    }
    img {
      height: 100%;
      @include mq(md) {
        height: 56vw;
      }
    }
    &::after {
      content: "";
      background-image: url(/images/index/line_left_long.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 100px;
      height: 10px;
      position: absolute;
      bottom: -50px;
      right: 0;
      @include mq(md) {
        width: 14vw;
        bottom: -13vw;
        right: 5.3%;
      }
    }
  }
}