//----------------------------------------------------

/*! Type: YakuHanJP_Noto - Based on Noto Sans CJK JP */
//----------------------------------------------------
$bebas-neue-weight: (
  300: Light,
  500: Regular,
  700: Bold,
) !default;

@each $key, $value in $bebas-neue-weight {
  @font-face {
    font-family: "BebasNeue";
    src: url('./fonts/BebasNeue/BebasNeue-#{$value}.ttf') format("ttf"),
         url('./fonts/BebasNeue/BebasNeue-#{$value}.woff') format("woff");

    font-style: normal;
    font-weight: $key;
  }
}