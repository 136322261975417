@charset "utf-8";
/* ================================================================================
トップページ
================================================================================ */

#indexTop {
  padding-top: 100px;
  @include mq(md) {
    padding-top: 16vw;
  }
  //共通
  .container {
    max-width: 1080px;
    margin: auto;
    width: 80%;
    @include mq(md) {
      width: 90%;
    }
  }
  .secTit {
    max-width: 1080px;
    margin: auto;
    width: 80%;
    @include mq(md) {
      width: 90%;
    }
    h2 {
      font-family: $BebasNeue;
      @include fz_vw_pc(80);
      line-height: 1;
      letter-spacing: 4px;
      color: #006cb6;
      display: inline-block;
      position: relative;
      @include mq(md) {
        @include fz_vw_md(110);
      }
      &::after {
        content: "";
        width: 100vw;
        height: 1px;
        background-color: #000;
        position: absolute;
        bottom: -3vw;
        right: -10px;
        @include mq(fixed) {
          bottom: -40px;
        }
        @include mq(md) {
          bottom: -50%;
          right: 10%;
        }
      }
      span {
        font-weight: 300;
      }
    }
    p {
      @include fz_vw_pc(14);
      line-height: 1;
      letter-spacing: 1.4px;
      color: #006cb6;
      padding-left: 2%;
      @include mq(md) {
        @include fz_vw_md(20);
        padding-left: 2.5%;
      }
    }
  }
  .pageTop {
    border-top: solid 1px #eeeeee;
  }
  //メインビジュアル
  @import "page/mainvisual";
  //About
  @import "page/about";
  //company
  @import "page/company";
  //history
  @import "page/history";
  //business
  @import "page/business";
  //access
  @import "page/access";
}