@charset "utf-8";
/* ボタン装飾
------------------------------------------------------------- */
.btnLayout {
  display: flex;
  a {
    padding: 10px 50px;
    background-color: #006cb6;
    @include fz_vw_pc(14);
    line-height: 1.75;
    letter-spacing: 1.4px;
    text-align: center;
    color: #fff;
    display: block;
    position: relative;
    transition: .5s;
    @include mq(md) {
      @include fz_vw_md(28);
      width: 100%;
    }
    &::after {
      content: "";
      background-image: url(/images/icons/icon_arrow.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 1.46vw;
      height: .8vw;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
      margin: auto;
      transition: .5s;
      @include mq(fixed) {
        width: 20px;
        height: 10px;
      }
      @include mq(md) {
        width: 5vw;
        height: 2vw;
        right: 6vw;
      }
    }
    &.hover {
      background-color: #3f98d9;
      &::after {
        transform: translateX(5px);
      }
    }
  }
  
  &.small {
    a {
      @include mq(md) {
        width: 88%;
        margin: auto;
      }
    }
  }
}